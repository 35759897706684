<template>
  <Header
      :isMobile="isMobile" :languageEng="languageEng"
  />
  <router-view :isMobile="isMobile" :languageEng="languageEng"/>
</template>

<script>

import {defineComponent} from "vue";
import Header from "@/components/Header";
export default  defineComponent({
  name : 'Landing Template',
  components: {Header},
  props: {
    isMobile: {
      type : Boolean
    },
    languageEng: {
      type: Boolean
    }
  },
  setup() {
    return {

    }
  }
})
</script>

<style scoped>

</style>
