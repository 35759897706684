<template>
  <transition-group name="fade" mode="out-in">
    <div
        @click="Store.dispatch('UI/changePopupFormIsOpen', !isOpen)"
        v-if="isOpen"
        class="popup-form-wrapper"
    >
      <div
          class="popup-form__content"
          id="popup-form"
          @click="event => event.stopPropagation()"
      >
      </div>
    </div>
  </transition-group>
</template>


<script>
import {defineComponent, computed, onUnmounted} from "vue";
import {useStore} from "vuex";

export default  defineComponent({
  name : 'Popup Window',
  emits: ['close'],
  props: {
    isMobile: {
      type : Boolean
    },
  },
  setup() {
    // onMounted(() => {
    //   document.body.classList.add('fixed')
    // })

    const Store = useStore();

    const isOpen = computed(() => Store.getters['UI/popupFormIsOpen'])


    onUnmounted(() => {
      document.body.classList.remove('fixed');
    })

    return {
      isOpen,
      Store
    }
  }
})
</script>

<style src="./popupForm.css" scoped>

</style>


