<template>
    <div
        class="select-list-wrapper"
        ref="wrapper"
    >
      <ul class="select-list"
          :style="{marginTop: -(wrapperStyle.top / 1.5) + 'px'}"
      >
        <li
            @click="changeValue(item)"

            v-for="item in selectList"

            :key="item"

            class="select-list__item"

            :class="JSON.stringify(item) === JSON.stringify(selected) ? 'selected_value' : ''"
        >
          <template
              v-if="formate_display"
          >
            {{item[`${formate_display}`]}}
          </template>
          <template v-else>
            {{item}}
          </template> 
        </li>
      </ul>
    </div>
</template>

<script>
import {defineComponent, ref, onMounted, onUnmounted} from "vue";
import {useStore} from "vuex";

export default  defineComponent({
  name : 'Select List',
  emits: ['change'],
  props: {
    selectList : {
      type : Array,
    },
    selected : {
      type:  [String, Object]
    },
    formate_display: {
      type: String
    }
  },
  setup(props, context) {
    const Store = useStore();
    const wrapper = ref(null);
    const wrapperStyle = ref({});

    onMounted(() => {
      const wrapperHeight = wrapper.value.clientHeight;
      console.log(props.selectList)
      const countElements = props.selectList.length - 2;
      const wrapperTop = wrapperHeight * countElements;
      wrapperStyle.value = {
        top: wrapperTop,
      }
      document.body.classList.toggle('fixed');
    })

    onUnmounted(() => {
      document.body.classList.toggle('fixed');
    })

    async function changeValue (item) {
      if(item == 'Сначала дешёвые' || item == 'Increase prise') {
        await Store.dispatch('cars/fetCarPriceMin')
      } else if(item == 'Сначала дорогие' || item == 'Decrease price') {
        await Store.dispatch('cars/fetCarPriceMax')
      } 
      context.emit('change', item);
      Store.dispatch('UI/changeIsOpenClosedList', false);
    }

    return {
      wrapper,
      wrapperStyle,
      changeValue,
    }
  }
})
</script>

<style scoped>
.select-list-wrapper {
  position: absolute;
  top: -40%;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  z-index: 999999999999;

}
.selected_value {
  color:var(--brand-color);
}
.select-list {
  box-shadow: 0px 1px 4px var(--black-25);
  z-index: 998;
}
.select-list__item {
  cursor: pointer;
  padding: 10px 15px;
}
.select-list__item:hover {
  background: var(--light-gray);
}
.select-list {
  background: var(--white);
  border-radius: 3px;
  max-height: calc(100vh - 120px);
  overflow: auto;
}
</style>
