<template>
  <template v-if="!isMobile">
    <ul class="offers-list">
      <OffersListItem
          v-for="offers in offers_list"
          :key="offers.id"
          :data="offers"
      />
    </ul>
  </template>
  <template v-else>
    <Swiper
      @swiper="onSwiper"
      :breakpoints="breakPoints"
      class="offers-slider"
      wrapper-tag="ul"
    >
      <SwiperSlide
          v-for="offers in offers_list"
          :key="offers.id"
          :slidesPerView="1.2"
          :spaceBetween="10"

      >
        <OffersListItem
            :data="offers"
            :is-mobile="isMobile"
            :languageEng="languageEng"
        />
      </SwiperSlide>
    </Swiper>
  </template>
</template>


<script>
import {defineComponent} from "vue";
// Компоненты
import OffersListItem from "@/components/offers/OffersListItem";
import {Swiper, SwiperSlide} from "swiper/vue";

export default  defineComponent({
  name : 'Offers List',
  components : {OffersListItem, Swiper, SwiperSlide},
  props: {
    offers_list: {
      type : Array
    },
    isMobile: {
      type : Boolean
    },
    languageEng: {
      type: Boolean
    }
  },
  setup() {
    const breakPoints = {
      320: {
        slidesPerView: 1.2,
        spaceBetween: 10
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 10
      },
    }
    function onSwiper (swiper) {
      swiper.$wrapperEl[0].classList.add('offers-list')
    }
    return {
      onSwiper,
      breakPoints
    }
  }
})
</script>

<style src="./offers.css" scoped>

</style>
<style>
/* Для слайдера */
.offers-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
    .offers-list {
      flex-wrap: nowrap;
      height: 40vh;
    }
}
</style>

