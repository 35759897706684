<template>
  <div class="container">
    <div class="section">
      <CarSlider class="slider-banner-catalog"
        :data="carsImagesDesktop" :languageEng="languageEng"
      />
      <CarSlider class="slider-banner-catalog-mobile"
        :data="carsImagesMobile" :languageEng="languageEng"
      />
      <h1 class="section-title">{{ languageEng ? 'Cars for rent' : 'Доступные автомобили' }}</h1> 
      <div class="filters">
          <div class="filters-top filters-top-desktop">
            <SelectMiniatureWithTitle
                :selectList="location_list"
                :selected="location_list[0]"
                :setParentValue="(value) => {fillers.start_location = value}"
                :title="languageEng ? 'Start location' : 'Место выдачи авто'"
                :languageEng="languageEng"
            />
            <div class="date_select row">
              <SelectMiniatureCalendarWithTitle
                  :name="'Дата начала аренды'"
                  :value="fillers.trip_start"
                  v-model="fillers.trip_start"
                  :title="languageEng ? 'Trip start' : 'Дата начала аренды'"
                  :languageEng="languageEng"
                  :checkDate="checkDateFrom"
              />
              <SelectMiniatureCalendarWithTitle
                  :name="'Дата окончания аренды'"
                  :value="fillers.trip_end"
                  v-model="fillers.trip_end"
                  :title="languageEng ? 'Trip end' : 'Дата окончания аренды'"
                  :languageEng="languageEng"
                  :checkDate="checkDateTo"
              />

            </div>
          </div>
          <div class="filters-bottom filters-top-desktop">

            <SelectMiniature
                :title="languageEng ? 'Order By:' : 'Сортировать:'"
                :selected="fillers.sort_by"
                :setParentValue="(value) => {fillers.sort_by = value}"
                :selectList="sorted_list"
                :languageEng="languageEng"
            />
            <!-- <SelectMiniature
                title="Car Owner:"
                :selected="fillers.car_owner"
                :setParentValue="(value) => {fillers.car_owner = value}"
                :selectList="car_owner_list"
            /> -->

          </div>
          <div class="filters-top filters-top-mobile">
            <SelectMiniatureWithTitle
                :title="languageEng ? 'Order By:' : 'Сортировать:'"
                :selected="fillers.sort_by"
                :setParentValue="(value) => {fillers.sort_by = value}"
                :selectList="sorted_list"
                :languageEng="languageEng"
            />
            <div class="filters-buttons">
              <div
                  @click="Store.dispatch('UI/changePopupFormIsOpen', true)"
                  class="filters-button filters-setting">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.5 22.5L26.25 22.5" stroke="#A4A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.75 22.5H6.25" stroke="#A4A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.375 25.625C11.1009 25.625 12.5 24.2259 12.5 22.5C12.5 20.7741 11.1009 19.375 9.375 19.375C7.64911 19.375 6.25 20.7741 6.25 22.5C6.25 24.2259 7.64911 25.625 9.375 25.625Z" stroke="#A4A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M25 15H26.25" stroke="#A4A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.75 15H12.5" stroke="#A4A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M20.625 18.125C22.3509 18.125 23.75 16.7259 23.75 15C23.75 13.2741 22.3509 11.875 20.625 11.875C18.8991 11.875 17.5 13.2741 17.5 15C17.5 16.7259 18.8991 18.125 20.625 18.125Z" stroke="#A4A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16.25 7.5H26.25" stroke="#A4A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.75 7.5H5" stroke="#A4A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.125 10.625C14.8509 10.625 16.25 9.22589 16.25 7.5C16.25 5.77411 14.8509 4.375 13.125 4.375C11.3991 4.375 10 5.77411 10 7.5C10 9.22589 11.3991 10.625 13.125 10.625Z" stroke="#A4A3A3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          <!-- <div class="filters-bottom">
            <SelectMiniatureWithTitle
                title="Car owner"
                :selected="fillers.car_owner"
                :setParentValue="(value) => {fillers.car_owner = value}"
                :selectList="car_owner_list"
            />
          </div> -->
      </div>

      <div class="catalog__list">
        <CatalogItem
            v-for="car in cars"
            :key="car.id"
            :data="car"
            :isMobile="isMobile"
            :languageEng="languageEng"
        />
      </div>
    </div>


    <template v-if="filtersIsOpen">
      <Teleport to="#popup-form">
        <PopupFormTemplate
          title="Filters"
          :languageEng="languageEng"
        >

          <SelectMiniatureWithTitle
              :title="languageEng ? 'Start location' : 'Место выдачи авто'"
              :selected="location_list[0]"
              :setParentValue="(value) => {fillers.start_location = value}"
              :selectList="location_list"
              :languageEng="languageEng"
          />

          <div class="date_select col">
            <SelectMiniatureCalendarWithTitle
                :name="'trip_start'"
                :value="fillers.trip_start"
                v-model="fillers.trip_start"
                :title="languageEng ? 'Trip start' : 'Дата начала аренды'"
                :languageEng="languageEng"
            />
            <SelectMiniatureCalendarWithTitle
                :name="'trip_end'"
                :languageEng="languageEng"
                :value="fillers.trip_end"
                v-model="fillers.trip_end"
                :title="languageEng ? 'Trip end' : 'Дата окончания аренды'"
            />

          </div>

          <template #form__bottom>
            <button class="button">{{ languageEng ? 'Применить фильтры' : 'Accept filters'}}</button>
          </template>
        </PopupFormTemplate>
      </Teleport>
    </template>

  </div>
</template> 


<script>
import {defineComponent, reactive, computed} from "vue";
import {useStore} from "vuex";
// import { useStoreCars } from "../store/modules/cars"

// Компоненты
import SelectMiniatureWithTitle from "@/components/forms/components/selectMiniatureWithTitle/SelectMiniatureWithTitle";
import SelectMiniatureCalendarWithTitle from "@/components/forms/components/selectMiniatureWithTitle/SelectMiniatureWithTitleCalendar";
import SelectMiniature from '@/components/forms/components/selectMiniature/SelectMiniature'
import CatalogItem from "@/components/catalogItem/CatalogItem";
import PopupFormTemplate from "@/components/template/popupFormTemplate/popupFormTemplate";
import CarSlider from "@/components/carSlider/carSlider";

import {getTodayDate} from "@/utills/date";

export default  defineComponent({
  name : 'Catalog',
  components : {
                  SelectMiniatureWithTitle, SelectMiniatureCalendarWithTitle,
                  SelectMiniature, CatalogItem, PopupFormTemplate, CarSlider
               },
  props: {
    isMobile: {
      type : Boolean
    },
    languageEng: {
      type: Boolean
    }
  },
  setup(props) {
    const Store = useStore();

    const filtersIsOpen = computed(() => Store.getters['UI/popupFormIsOpen'])

    const checkDateFrom = () => { 
      let date_form_date = new Date(fillers.trip_start.match(/\d\d\d\d-\d\d-\d\d/)[0].replace(/'-'/gi, '.'))

      let date_to_date = new Date(fillers.trip_end.match(/\d\d\d\d-\d\d-\d\d/)[0].replace(/'-'/gi, '.'))

      let date_res = (date_form_date - date_to_date) / (60 * 60 * 24 * 1000)
  console.log(date_res < 0)
      if(date_res < 0) {
        fillers.trip_start = getTodayDate()
      } else {
        Store.commit('forms/setDateMain', {date_start: fillers.trip_start, date_end: fillers.trip_end})
      }
    }

    const checkDateTo = () => {
      let date_form_date = new Date(fillers.trip_start.match(/\d\d\d\d-\d\d-\d\d/)[0].replace(/'-'/gi, '.'))

      let date_to_date = new Date(fillers.trip_end.match(/\d\d\d\d-\d\d-\d\d/)[0].replace(/'-'/gi, '.'))
      console.log(fillers)
      console.log(date_res)
      let date_res = (date_to_date - date_form_date) / (60 * 60 * 24 * 1000)
      console.log(date_res)
      if(date_res < 0) {
        fillers.trip_end = getTodayDate()
      } else {
        Store.commit('forms/setDateMain', {date_start: fillers.trip_start, date_end: fillers.trip_end})
      }
    }

    const location_list = ['Batumi Airport', 'Center Batumi'];
    const sorted_list = props.languageEng ? ['Increase prise','Decrease price'] : ['Сначала дешёвые','Сначала дорогие'];
    const car_owner_list = ['Commercial', 'Commercial 2'];
    const carsImagesDesktop = ['/images/slider-catalog-1.jpg', '/images/slider-catalog-2.jpg'];

    const carsImagesMobile = ['/images/slider-catalog-mobile-1.jpg', '/images/slider-catalog-mobile-2.jpg'];

    const fillers = reactive({
        start_location : '',
        trip_start: Store.getters['forms/getMainDateStart'] ? Store.getters['forms/getMainDateStart'] : getTodayDate(),
        trip_end : Store.getters['forms/getMainDateEnd'] ? Store.getters['forms/getMainDateEnd'] : getTodayDate(),
        sort_by : sorted_list[0],
        car_owner : car_owner_list[0],
    })
    let cars = computed(() => Store.getters['cars/getCars'])
    const fetchCars = async () => {
      await Store.dispatch('cars/fetCarPriceMin')
    }
    fetchCars()
    return {
      fillers, 
      location_list,
      sorted_list,
      car_owner_list, 
      cars,
      filtersIsOpen,
      Store,
      carsImagesDesktop,
      carsImagesMobile,
      checkDateFrom,
      checkDateTo
    }
  },
})
</script>

<style src="../assets/styles/catalog.css" scoped>

</style>


