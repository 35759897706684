<template>
    <div class="container --container-80">
        <div class="blog">
          <h2>{{blog.title}}</h2>
            <div class="blog-info__inner">
                <p class="blog-info__text" v-html="blog.description"></p>
                <img class="blog-info__img" src="/images/slider-catalog-1.jpg" alt="">
            </div>
        </div>
    </div> 
</template> 

<script>
  import {defineComponent, onMounted, computed} from "vue";
  import {useStore} from "vuex";
  import {useRoute} from 'vue-router'

  export default defineComponent({
    name: 'Blog Info',
    props: {
      languageEng: Boolean 
    },
    validate({ params }) {
      return /^\d+$/.test(params.id);
    },   
    setup (props) {
      const Store = useStore();

      const route = useRoute();

      onMounted(async () => {
        if(props.languageEng) {
          await Store.dispatch('blog/ActionBlogIdEng', route.params.id)
        } else {
          await Store.dispatch('blog/ActionBlogId', route.params.id)
        }
      })

      let blog = computed(() => Store.getters['blog/getBlogId'])

      return {
        blog
      }
    }
  })
</script>

<style scoped src="../assets/styles/blog.css">
</style>