<template>
  <div
      class="input-box textarea"
  >
    <label :for="name" class="input-box__label">
      {{label}}
    </label>
    <textarea
        class="input-textarea"
        :class="options?.$error ? 'not-invalid' : ''"
        :type="type"
        :id="name"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        @input="updateValue"
    ></textarea>
    <slot name="message"></slot>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default  defineComponent({
  name : 'Input',
  props: {
    modelValue: {
      type : String
    },
    name : {
      type : String,
    },
    value : {
      type: String,
    },
    placeholder : {
      type: String,
      default: 'nothing...'
    },
    type : {
      type: String,
      default : 'text'
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    label : {
      type : String
    },
    options : {
      type: Object
    },
  },
  setup(props, context) {
    function updateValue(event) {
      context.emit("update:modelValue", event.target.value);
    }
    return {
      props,
      updateValue
    }
  },
})
</script>

<style src="../inputs/inputType2.css" scoped>

</style>
