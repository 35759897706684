<template>
  <div
      class="form"
  >
    <div class="form-content">
      <Input
          :languageEng="languageEng"
          :label="languageEng ? 'First name' : 'Имя'"
          :placeholder="languageEng ? 'First name' : 'Имя'"
          name="first_name"
          v-model="formData.first_name"
          @input="v$.first_name.$touch()"
          :options="v$.first_name"
      >
        <template #message>
          <span v-if="v$.first_name.$dirty & v$.first_name.$errors.length" class="message message--error">{{v$.first_name.$silentErrors[0].$message}}</span>
        </template>
      </Input>

      <Input
          :languageEng="languageEng"
          :label="languageEng ? 'Last name' : 'Фамилия'"
          :placeholder="languageEng ? 'Last name' : 'Фамилия'"
          name="last_name"
          v-model="formData.last_name"
          @input="v$.last_name.$touch()"
          :options="v$.last_name"
      >
        <template #message>
          <span v-if="v$.last_name.$dirty & v$.last_name.$errors.length" class="message message--error">{{v$.last_name.$silentErrors[0].$message}}</span>
        </template>
      </Input>

      <Input
          :languageEng="languageEng"
          label="Email"
          placeholder="test@mail.ri"
          name="email"
          v-model="formData.email"
          @input="v$.email.$touch()"
          :options="v$.email"
      >
        <template #message>
          <span v-if="v$.email.$dirty & v$.email.$errors.length" class="message message--error">{{v$.email.$silentErrors[0].$message}}</span>
        </template>
      </Input>

      <Input
          :languageEng="languageEng"
          :label="languageEng ? 'Phone' : 'Номер телефона'"
          placeholder="+995 568 946 240"
          name="phone"
          v-model="formData.phone"
          @input="v$.phone.$touch()"
          :options="v$.phone"
      >
        <template #message>
          <span v-if="v$.phone.$dirty & v$.phone.$errors.length" class="message message--error">{{v$.phone.$silentErrors[0].$message}}</span>
        </template>
      </Input>

      <Input
          :languageEng="languageEng"
          :label="languageEng ? 'Age' : 'Возраст'"
          :placeholder="languageEng ? 'How old are you' : 'Сколько вам лет?'"
          name="age"
          v-model="formData.age"
          @input="v$.age.$touch()"
          :options="v$.age"
      >
        <template #message>
          <span v-if="v$.age.$dirty & v$.age.$errors.length" class="message message--error">{{v$.age.$silentErrors[0].$message}}</span>
        </template>
      </Input>

      <Input
          :languageEng="languageEng"
          :label="languageEng ? 'Country' : Страна"
          :placeholder="languageEng ? 'USA': 'США'"
          name="age"
          v-model="formData.country"
          @input="v$.country.$touch()"
          :options="v$.country"
      >
        <template #message>
          <span v-if="v$.country.$dirty & v$.country.$errors.length" class="message message--error">{{v$.country.$silentErrors[0].$message}}</span>
        </template>
      </Input>
      <InputTextArea
          :languageEng="languageEng"
          :label="languageEng ? 'Wishes' : 'Пожелания'"
          :placeholder="languageEng ? 'Thanks for the quality work' : 'Спасибо за качественную работу'"
          name="wishes"
          v-model="formData.wishes"

      >
      </InputTextArea>
    </div>

    <div class="form__bottom">
      <template v-if="current_step < last_step">
        <div
            @click="backStep"
            class="form__back">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.75 16C24.3023 16 24.75 15.5523 24.75 15C24.75 14.4477 24.3023 14 23.75 14V16ZM6.25 14C5.69772 14 5.25 14.4477 5.25 15C5.25 15.5523 5.69772 16 6.25 16L6.25 14ZM23.75 14L6.25 14L6.25 16L23.75 16V14Z" fill="#E1E1E1"/>
            <path d="M13.0429 23.2071C13.4334 23.5976 14.0666 23.5976 14.4571 23.2071C14.8476 22.8166 14.8476 22.1834 14.4571 21.7929L13.0429 23.2071ZM6.25 15L5.54289 14.2929C5.35536 14.4804 5.25 14.7348 5.25 15C5.25 15.2652 5.35536 15.5196 5.54289 15.7071L6.25 15ZM14.4571 8.20711C14.8476 7.81658 14.8476 7.18342 14.4571 6.79289C14.0666 6.40237 13.4334 6.40237 13.0429 6.79289L14.4571 8.20711ZM14.4571 21.7929L6.95711 14.2929L5.54289 15.7071L13.0429 23.2071L14.4571 21.7929ZM6.95711 15.7071L14.4571 8.20711L13.0429 6.79289L5.54289 14.2929L6.95711 15.7071Z" fill="#E1E1E1"/>
          </svg>
          {{ languageEng ? 'Come back': 'Вернуться назад'}} {{current_step - 1}}
        </div>
      </template>
      <button
          class="button"
          :class="v$.$silentErrors.length === 0 ? '' : 'form__button--disabled'"
          @click="submit"
          :disabled="v$.$silentErrors.length === 0 ? false : true"
      >{{ last_step > current_step ? (languageEng ? 'Proceed' : 'Продолжить') : (languageEng ? 'Send': 'Отправить') }} </button>
    </div>
  </div>
</template>


<script>
import {defineComponent, reactive} from "vue";



// Валидация
import {validatorsMessage, isFullName, isNumberPhone, isAdult} from "../../../../../validations";
import useVuelidate from "@vuelidate/core";
import {helpers, email, required} from "@vuelidate/validators";
import {useStore} from "vuex";
// Компоненты
import Input from "@/components/forms/components/inputs/Input";
import InputTextArea from "@/components/forms/components/textareas/InputTextArea";
import {useRoute} from "vue-router";



export default  defineComponent({
  name : 'Payment Info Form',
  emits: ['change'],
  components: {Input, InputTextArea},
  props: {
    isMobile: {
      type : Boolean
    },
    current_step: {
      type : Number
    },
    last_step : {
      type : Number
    },
    languageEng: {
      type: Boolean
    },
  },
  setup(context, props) {
    const Store = useStore();
    const route = useRoute()

    const formData = reactive({
      first_name : '',
      last_name : '',
      email: '',
      phone: '',
      age: '',
      country: '',
      wishes: '',
      date: '',
      price: '',
    })

    const rules =  {
      first_name : {
        isFullName : helpers.withMessage(() => `${validatorsMessage.name.error}`, isFullName),
      },
      last_name : {
        isFullName : helpers.withMessage(() => `${validatorsMessage.name.error}`, isFullName),
      },
      email : {
        email: helpers.withMessage(() => `${validatorsMessage.email.error}`, email),
      },
      phone: {
        isNumberPhone: helpers.withMessage(() => `${validatorsMessage.phone.error}`, isNumberPhone)
      },
      country: {
        required: required
      },
      age: {
        isAdult: helpers.withMessage(() => `${validatorsMessage.age.error}`, isAdult)
      },
    }

    const v$ = useVuelidate(rules, formData)

    function backStep () {
      Store.dispatch('forms/setPaymentStep', props.current_step - 1);
    }

    function submit () {
        formData.date = Store.getters['forms/getDate'].date
        formData.price = Store.getters['forms/getPrice']
      if(v$.value.$silentErrors.length === 0) {
        Store.dispatch('forms/OrderCar', {id: route.params.id, data: formData})
        Store.dispatch('messages/appendMessage', {text: (props.languageEng ? 'Submit Confirm' : 'Форма отправленна'), type: true})
        Store.dispatch('UI/changePopupFormIsOpen', false)
        context.emit('change', {client_info : formData});
      } else { 
        Store.dispatch('messages/appendMessage', {text: (props.languageEng ? 'Form not completed' :'Форма не заполнена'), type: false})
      }
    }
    return {
      v$,
      formData,
      Input,
      submit,
      backStep,
    }
  }
})
</script>

<style scoped src="../../../../assets/styles/form.css">

</style>


