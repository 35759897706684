



<!--<template>-->
<!--  <div-->
<!--      class="car-slider"-->
<!--      :class="wrapperConfig.isGrabbing ? 'grabbing' : 'grab'"-->
<!--      @mousedown="swipeStart"-->
<!--      @mouseup="swipeEnd"-->
<!--  >-->
<!--    <ul-->
<!--        class="car-slider__wrapper"-->
<!--        ref="wrapper"-->
<!--        :style="wrapperConfig.style"-->
<!--    >-->
<!--      <li-->
<!--          v-for="(slide, index) in data"-->
<!--          :key="slide.id"-->

<!--          ref="slider_items"-->

<!--          class="car-slider__item"-->

<!--      >-->
<!--        <img :src="slide" :alt="index">-->
<!--      </li>-->
<!--    </ul>-->
<!--    <div class="car-slider__navigation">-->
<!--      <svg-->
<!--          @click="prevSlide()"-->
<!--          width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M16.7929 20.7071C17.1834 21.0976 17.8166 21.0976 18.2071 20.7071C18.5976 20.3166 18.5976 19.6834 18.2071 19.2929L16.7929 20.7071ZM12.5 15L11.7929 14.2929C11.4024 14.6834 11.4024 15.3166 11.7929 15.7071L12.5 15ZM18.2071 10.7071C18.5976 10.3166 18.5976 9.68342 18.2071 9.29289C17.8166 8.90237 17.1834 8.90237 16.7929 9.29289L18.2071 10.7071ZM18.2071 19.2929L13.2071 14.2929L11.7929 15.7071L16.7929 20.7071L18.2071 19.2929ZM13.2071 15.7071L18.2071 10.7071L16.7929 9.29289L11.7929 14.2929L13.2071 15.7071Z" fill="white"/>-->
<!--      </svg>-->
<!--      <span>{{slide + 1}} / {{data.length}}</span>-->
<!--      <svg-->
<!--          @click="nextSlide()"-->
<!--          width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M13.2071 9.29289C12.8166 8.90237 12.1834 8.90237 11.7929 9.29289C11.4024 9.68342 11.4024 10.3166 11.7929 10.7071L13.2071 9.29289ZM17.5 15L18.2071 15.7071C18.5976 15.3166 18.5976 14.6834 18.2071 14.2929L17.5 15ZM11.7929 19.2929C11.4024 19.6834 11.4024 20.3166 11.7929 20.7071C12.1834 21.0976 12.8166 21.0976 13.2071 20.7071L11.7929 19.2929ZM11.7929 10.7071L16.7929 15.7071L18.2071 14.2929L13.2071 9.29289L11.7929 10.7071ZM16.7929 14.2929L11.7929 19.2929L13.2071 20.7071L18.2071 15.7071L16.7929 14.2929Z" fill="white"/>-->
<!--      </svg>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->


<!--<script>-->
<!--import {defineComponent, reactive, ref, onMounted} from "vue";-->
<!--export default  defineComponent({-->
<!--  name : 'Car Slider',-->
<!--  props: {-->
<!--    isMobile: {-->
<!--      type : Boolean-->
<!--    },-->
<!--    data: {-->
<!--      type : Array,-->
<!--    }-->
<!--  },-->
<!--  setup(props) {-->
<!--    const slider_items = ref(null);-->
<!--    const slide = ref(0);-->
<!--    const wrapper = ref(null);-->
<!--    const wrapperConfig = reactive({-->
<!--      style : {-->
<!--        transform: 'translate3d(0px, 0px, 0px)',-->
<!--        width : '',-->
<!--        marginLeft: '',-->
<!--        transition: '',-->
<!--        isGrabbing : false,-->
<!--      },-->
<!--      widthOneElement: '',-->
<!--    })-->

<!--    const transformRegexp = /[-0-9.]+(?=px)/-->
<!--    const dinamicTransformX = ref(0);-->
<!--    const positionThreshold = ref(0)-->
<!--    let positionStart = ref(0);-->
<!--    let positionCurrent = ref(0);-->
<!--    let positionPrev = ref(0);-->
<!--    let positionY1 = ref(0);-->
<!--    let positionY2 = ref(0);-->
<!--    let positionFinal = ref(0);-->

<!--    onMounted(() => {-->
<!--      wrapperConfig.widthOneElement = slider_items.value[0].clientWidth;-->
<!--      wrapperConfig.style.width = `${wrapperConfig.widthOneElement * slider_items.value.length}px`;-->
<!--      positionThreshold.value = wrapperConfig.widthOneElement * 0.35;-->
<!--      wrapperConfig.style.marginLeft = `${wrapperConfig.widthOneElement}px`-->
<!--      wrapper.value.addEventListener('touchstart', swipeStart, {passive: true});-->
<!--      wrapper.value.addEventListener('touchend', swipeEnd, {passive: true});-->
<!--    })-->

<!--    function nextSlide () {-->
<!--      if(slide.value < props.data.length - 1) {-->
<!--        slide.value++-->
<!--        wrapperConfig.style.transform = `translate3d(-${slide.value * wrapperConfig.widthOneElement}px, 0px, 0px)`;-->
<!--      }-->
<!--    }-->
<!--    function prevSlide () {-->
<!--      if(slide.value > 0) {-->
<!--        slide.value&#45;&#45;-->
<!--        wrapperConfig.style.transform = `translate3d(-${slide.value * wrapperConfig.widthOneElement}px, 0px, 0px)`;-->
<!--      }-->
<!--    }-->

<!--    function slideBack () {-->
<!--      wrapperConfig.style.transform = `translate3d(-${slide.value * wrapperConfig.widthOneElement}px, 0px, 0px)`-->
<!--    }-->

<!--    function swipeStart (event) {-->
<!--      positionStart.value = event.clientX-->
<!--      positionCurrent.value = event.clientX-->
<!--      wrapper.value.addEventListener('mousemove', swipeAction, false);-->
<!--      wrapper.value.addEventListener('touchmove', swipeAction, {passive: true});-->
<!--      wrapperConfig.style.transition = ''-->
<!--      wrapperConfig.isGrabbing = true-->
<!--    }-->

<!--    function swipeAction (event) {-->
<!--      dinamicTransformX.value =  +wrapperConfig.style.transform.match(transformRegexp)[0]-->

<!--      positionPrev.value = positionCurrent.value - event.clientX;-->
<!--      positionCurrent.value = event.clientX;-->


<!--      wrapperConfig.style.transform = `translate3d(${dinamicTransformX.value - positionPrev.value}px, 0px, 0px)`-->
<!--    }-->
<!--    function swipeEnd () {-->

<!--      positionFinal.value = positionStart.value - positionCurrent.value-->

<!--      if (Math.abs(positionFinal.value) >= positionThreshold.value) {-->

<!--        if(positionStart.value > positionCurrent.value) {-->
<!--          nextSlide()-->
<!--        } else if (positionStart.value < positionCurrent.value) {-->
<!--          prevSlide()-->
<!--        }-->
<!--      } else {-->
<!--        slideBack();-->
<!--      }-->
<!--      if(slide.value === props.data.length - 1-->
<!--          && (positionStart.value - positionCurrent.value) >= positionThreshold.value) {-->
<!--        slideBack();-->
<!--      } else {-->
<!--        slideBack();-->
<!--      }-->
<!--      wrapperConfig.isGrabbing = false-->
<!--      wrapperConfig.style.transition = 'transform 0.3s ease'-->
<!--      wrapper.value.removeEventListener('mousemove', swipeAction, false)-->
<!--      wrapper.value.removeEventListener('touchmove', swipeAction, false);-->
<!--    }-->

<!--    return {-->
<!--      slide,-->
<!--      wrapper,-->
<!--      wrapperConfig,-->
<!--      slider_items,-->
<!--      swipeStart,-->
<!--      swipeEnd,-->
<!--      swipeAction,-->
<!--      positionCurrent,-->
<!--      positionPrev,-->
<!--      positionY1,-->
<!--      positionY2,-->
<!--      positionStart,-->
<!--      positionFinal,-->
<!--      positionThreshold,-->
<!--      dinamicTransformX,-->
<!--      nextSlide,-->
<!--      prevSlide-->
<!--    }-->
<!--  }-->
<!--})-->
<!--</script>-->

<!--<style src="./carSlider.css" scoped>-->

<!--</style>-->

<template>
  <Swiper
      class="car-slider"
      wrapper-tag="ul"
      @swiper="onSwiper"
      :breakpoints="breakPoints"
  >
    <SwiperSlide
        tag="li"
        v-for="(slide, index) in data"
        :key="index"
        class="car-slider__item"
    >
      <img :src="slide" :alt="index">
    </SwiperSlide>
  </Swiper>
</template>
<script>
import {defineComponent} from "vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

export default defineComponent({
  name : 'Car Slider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    isMobile: {
      type : Boolean
    },
    data: {
      type : Array,
    }
  },
  setup() {
    const breakPoints = {
      1100: {
        slidesPerView: 1,
        spaceBetween: 30,
      }
    }
    function onSwiper (swiper) {
      swiper.$wrapperEl[0].classList.add('car-slider__wrapper')
    }
    // function onSlideChange (slide) {
    //   console.log(slide)
    // }
    return {
      onSwiper,
      breakPoints
    };
  },
})
</script>

<style src="./carSlider.css" scoped>
</style>
