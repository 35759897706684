import axios from "axios";

export  default  {
    namespaced : true,
    state : {
        paymentFormData: {
            payment_method : '',
            client_info : {},
            card : {},
            current_step: 1,
            price: '',
        },
        date: '',
        main_date_start: '',
        main_date_end: '',
    },
    mutations: {
        setPaymentFormData (state, data) {
            state.paymentFormData = data;
        },
        setPaymentStep(state, step) {
            state.paymentFormData.current_step = step;
        },
        setDate(state, date) {
            state.date = date
        },
        setPrice(state, price) {
            state.price = price
        },
        setDateMain(state, days) {
            state.main_date_start = days.date_start
            state.main_date_end = days.date_end
        }
    },
    actions: {
        contactUs (context) {
            context.dispatch('messages/appendMessage', {text: 'Sumbit', type: true});
        },
        setPaymentStep (context, step) {
            context.commit('setPaymentStep', step);
        },
        AddingValuePaymentFormData (context, data) {
            context.commit('setPaymentFormData', {...context.getters['paymentFormData'], ...data});
        },
        async OrderCar(context, data) {  
            await axios.post(`https://api.rentauto24.ge/api/order/${data.id}`, data.data)
        },
        async SendMe(context, data) {
            await axios.post(`https://api.rentauto24.ge/api/sendme`, data)
        }
    },
    getters : {
        paymentFormData (state) {
            return state.paymentFormData
        },
        getDate(state) {
            return state.date
        },
        getPrice(state) {
            return state.price
        },
        getMainDateStart(state) {
            return state.main_date_start
        },
        getMainDateEnd(state) {
            return state.main_date_end
        }
    }
}
