<template>
  <div
      class="select-miniature__wrapper --calendar">
    <h3 class="select-miniature__title">{{title}}</h3>
    <div
        class="select-miniature"
    >
    <input
          type="datetime-local"
          class="input-calendar"
          :id="name"
          :name="name"
          :value="value"
          min="2022-01-01"
          max="2022-12-31"
          @input="updateValue"
      />
    </div> 
  </div>
</template>

 
<script>
import {defineComponent} from "vue";
import {formatDate} from "@/utills/date";

// Компоненты
export default  defineComponent({
  name : 'Select Miniature Calendar',
  props: {
    isMobile: {
      type : Boolean
    },
    modelValue : {
      type: String,
    },
    name: {
      type : String
    },
    value : {
      type: String,
    }, 
    title : {
      required: true,
      type : String,
    },
    languageEng : {
      type: Boolean
    },
    checkDate : {
      type: Function
    }
  },
  setup(props, context) {
    function updateValue(event) {
      context.emit("update:modelValue", event.target.value);
      if(props.checkDate) {
          props.checkDate()
      }
    }

    return {
      updateValue,
      formatDate,
      props,
    }
  }
})
</script>

<style src="./selectMiniatureWithTitle.css" scoped>

</style>


