<template>
    <div class="burger">
      <div class="burger__content">
        <div
            @click="$emit('close', false)"
            class="burger__close">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5 22.5L7.5 7.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.5 7.5L7.49997 22.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <nav class="header__navigation">
          <router-link
              :to="{ name: 'Index page' }"
              class="header__navigation-link"
          >
            {{languageEng ? 'Main' : 'Главная'}}
          </router-link>
          <router-link
              :to="{ name: 'cars catalog' }"
              class="header__navigation-link"
          >
            {{languageEng ? 'Rent' : 'Аренда' }}
          </router-link>
          <router-link
              :to="{ name: 'about us' }"
              class="header__navigation-link"
          >
            {{languageEng ? 'About' : 'О нас' }}
          </router-link>
          <router-link
                :to="{ name: 'blog' }"
                class="header__navigation-link"
            >
            {{languageEng ? 'Blog' : 'Блог' }} 
        </router-link>
          <router-link
              :to="{ name: 'contacts' }"
              class="header__navigation-link"
          >
           {{languageEng ? 'Contact' : 'Контакты' }}
          </router-link>
        </nav>
        <div class="burger__change-language__container">
          <a :href="'https://eng.rentauto24.ge' + urlPathname">
            <div class="burger__change-language__item" :class="url == 'https://eng.rentauto24.ge' ? 'burger__change-language__item--active' : '' "> ENG </div>
          </a>
          <a :href="'https://rentauto24.ge' + urlPathname">
            <div class="burger__change-language__item" :class="url == 'https://rentauto24.ge' ? 'burger__change-language__item--active' : '' "> RUS </div>
          </a>
        </div>
      </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, onUnmounted} from "vue";

export default  defineComponent({
  name : 'Burger',
  emits: ['close'],
  props: {
    isMobile: {
      type : Boolean
    },
    isOpen : {
      type: Boolean
    },
    close : {
      type: Function
    },
    languageEng: {
      type: Boolean
    }
  },
  setup() {
    let url = window.location.origin
    let urlPathname = window.location.pathname

    onMounted(() => {
      document.body.classList.add('fixed')
    })
    onUnmounted(() => {
      document.body.classList.remove('fixed');
    })
    return {
      url,
      urlPathname
    }
  }
})
</script>

<style src="../assets/styles/header.css" scoped>

</style>

