<template>
  <Header
    class="default"
    :isMobile="isMobile"
    :languageEng="languageEng"
  />
  <router-view :isMobile="isMobile" :languageEng="languageEng"/>
  <Footer :isMobile="isMobile" :languageEng="languageEng" />
</template>

<script>

import {defineComponent} from "vue";
// Компоненты
import Footer from "@/components/Footer";
import Header from '@/components/Header';


export default  defineComponent({
  name : 'Landing Template',
  components: {Footer, Header},
  props: {
    isMobile: {
      type : Boolean
    },
    languageEng: {
      type: [Boolean]
    }
  },
  setup() {

    return {

    }
  }
})
</script>

<style scoped>

</style>
