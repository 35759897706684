<template>
  <div
      v-if="ready"
      @click="Store.dispatch('UI/changeClickableSelect', {...currentSelectFromStore, open: true})"
      class="select-miniature__wrapper">
    <div
        class="select-miniature"
        @click="Store.dispatch('UI/changeIsOpenClosedList', true)"
    >
      <span class="select-miniature__name">{{title}}</span>
      <div class="select-miniature__selected">
        <span class="select-miniature__value">{{selectedValue.value}}</span>
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5821 6.33211C12.9726 5.94158 12.9726 5.30842 12.5821 4.91789C12.1916 4.52737 11.5584 4.52737 11.1679 4.91789L12.5821 6.33211ZM7.5 10L6.79289 10.7071C7.18342 11.0976 7.81658 11.0976 8.20711 10.7071L7.5 10ZM3.83211 4.91789C3.44158 4.52737 2.80842 4.52737 2.41789 4.91789C2.02737 5.30842 2.02737 5.94158 2.41789 6.33211L3.83211 4.91789ZM11.1679 4.91789L6.79289 9.29289L8.20711 10.7071L12.5821 6.33211L11.1679 4.91789ZM8.20711 9.29289L3.83211 4.91789L2.41789 6.33211L6.79289 10.7071L8.20711 9.29289Z" fill="black"/>
        </svg>
      </div>
      <SelectList 
          :languageEng="languageEng"
          v-if="currentSelectFromStore.open"
          @click="event => event.stopPropagation()"
          :isMobile="isMobile"
          :selected="selectedValue.value"
          :selectList="selectList"
          @change="(value) => changeValue(value)"
      />
    </div>
  </div>
</template>


<script>
import {defineComponent, onMounted, ref} from "vue";
import SelectList from '@/components/SelectList'
import {useStore} from "vuex";
export default  defineComponent({
  name : 'Select Miniature',
  components : {SelectList},
  props: {
    isMobile: {
      type : Boolean
    },
    selected: {
      type : String
    },
    selectList : {
      type: Array
    },
    title : {
      type : String,
    },
    setParentValue: {
      required: true,
      type: Function
    },
    languageEng : {
      type: Boolean
    }
  },
  setup(props) {
    const Store = useStore();

    const ready = ref(false);

    const currentSelectFromStore = ref(null);

    onMounted(async () => {
      const currentSelectID = await Store.dispatch('UI/registeredSelectList');
      currentSelectFromStore.value = Store.getters['UI/findBySelectId'](currentSelectID);
      ready.value = true;
    })

    const selectedValue = ref({value : props.selected});

    function changeValue (value) {
      selectedValue.value.value = value;
      // props.setVariable(value);
      Store.dispatch('UI/changeClickableSelect', {...currentSelectFromStore.value, open: false});
    }

    return {
      selectedValue,
      changeValue,
      currentSelectFromStore,
      ready,
      open,
      props,
      Store,
    }
  }
})
</script>

<style src="./SelectMiniature.css" scoped>

</style>


