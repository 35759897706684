<template>
  <div class="info-car__col">
    <h4 class="section-title-h4">{{languageEng ? 'About car' : 'О машине'}}</h4>
    <ul class="features-list">
      <li
          v-if="data.car_people"
          class="features__item"
      >
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5 50C17.5 45.8579 23.0964 42.5 30 42.5C36.9036 42.5 42.5 45.8579 42.5 50" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M45 35.624C49.4148 36.7814 52.5 39.4245 52.5 42.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 35.624C10.5852 36.7814 7.5 39.4245 7.5 42.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M30 35C34.1421 35 37.5 31.6421 37.5 27.5C37.5 23.3579 34.1421 20 30 20C25.8579 20 22.5 23.3579 22.5 27.5C22.5 31.6421 25.8579 35 30 35Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M45 25.5903C46.5344 24.217 47.5 22.2212 47.5 20C47.5 15.8579 44.1421 12.5 40 12.5C38.0791 12.5 36.3269 13.2221 35 14.4097" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 25.5903C13.4656 24.217 12.5 22.2212 12.5 20C12.5 15.8579 15.8579 12.5 20 12.5C21.9209 12.5 23.6731 13.2221 25 14.4097" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span class="features__item-value">{{data.car_people}} {{languageEng ? 'people' : 'человек(а)'}}</span>
      </li>
      <li
          v-if="data.car_transmission"
          class="features__item"
      >
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M30 60C13.457 60 0 46.543 0 30C0 13.457 13.457 0 30 0C46.543 0 60 13.457 60 30C60 46.543 46.543 60 30 60ZM30 1.40625C14.2324 1.40625 1.40625 14.2324 1.40625 30C1.40625 45.7656 14.2324 58.5937 30 58.5937C45.7656 58.5937 58.5937 45.7676 58.5937 30C58.5937 14.2324 45.7656 1.40625 30 1.40625ZM30 54.1406C16.6895 54.1406 5.85937 43.3105 5.85937 30C5.85937 16.6895 16.6895 5.85937 30 5.85937C43.3105 5.85937 54.1406 16.6895 54.1406 30C54.1406 43.3105 43.3105 54.1406 30 54.1406ZM30.7031 38.4883V52.7246C42.6816 52.3574 52.3574 42.6816 52.7246 30.7031H38.4883C38.1484 34.8398 34.8398 38.1484 30.7031 38.4883ZM7.27734 30.7031C7.64258 42.6816 17.3184 52.3574 29.2969 52.7246V38.4883C25.1602 38.1484 21.8516 34.8398 21.5137 30.7031H7.27734ZM30 22.8906C26.0801 22.8906 22.8906 26.0801 22.8906 30C22.8906 33.9199 26.0801 37.1094 30 37.1094C33.9199 37.1094 37.1094 33.9199 37.1094 30C37.1094 26.0801 33.9199 22.8906 30 22.8906ZM38.4883 29.2969H52.7246C52.3516 17.084 42.3008 7.26562 30 7.26562C17.6992 7.26562 7.64844 17.084 7.27734 29.2969H21.5137C21.873 24.9297 25.541 21.4844 30 21.4844C34.459 21.4844 38.1289 24.9297 38.4883 29.2969Z" fill="black"/>
        </svg>
        <span class="features__item-value">{{data.car_transmission}}</span>
      </li>
      <li
          v-if="data.car_doors"
          class="features__item"
      >
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M59.3652 1.15395C58.7961 0.425977 57.9241 0 57.0002 0H27.0002C26.2042 0 25.4413 0.316055 24.8793 0.879023L0.879267 24.879C0.316299 25.4411 0.000244141 26.2041 0.000244141 27V57C0.000244141 58.657 1.34321 60 3.00024 60H51.0002C52.6573 60 54.0002 58.657 54.0002 57V27.369L59.9102 3.72703C60.1342 2.83102 59.9332 1.88203 59.3652 1.15395ZM51.0002 57H3.00024V30H51.0002V57ZM51.0002 27H3.00024L27.0002 3H57.0002L51.0002 27Z" fill="black"/>
          <path d="M48.0002 33H39.0002V36H48.0002V33Z" fill="black"/>
        </svg>
        <span class="features__item-value">{{data.car_doors}}{{languageEng ? ' Doors' : ' Двери'}}</span>
      </li>
      <li
          v-if="data.car_power"
          class="features__item"
      >
        <svg width="48" height="60" viewBox="0 0 48 60" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M43.4175 60H4.58252C2.05631 60 0 57.9316 0 55.3906V10.4688C0 8.16602 1.68738 6.25195 3.88349 5.91211V0.703125C3.88349 0.314453 4.19612 0 4.58252 0H24C24.3864 0 24.699 0.314453 24.699 0.703125V5.85937H43.4175C45.9456 5.85937 48 7.92773 48 10.4688V55.3906C48 57.9336 45.9456 60 43.4175 60ZM4.58252 7.26562C2.82718 7.26562 1.39806 8.70312 1.39806 10.4688V55.3906C1.39806 57.1562 2.82718 58.5937 4.58252 58.5937H43.4175C45.1728 58.5937 46.6019 57.1582 46.6019 55.3906V10.4688C46.6019 8.70312 45.1748 7.26562 43.4175 7.26562H4.58252ZM5.28155 5.85937H23.301V1.40625H5.28155V5.85937ZM37.5922 46.3281C37.4136 46.3281 37.233 46.2598 37.0971 46.123L9.91262 18.7793C9.63883 18.5039 9.63883 18.0605 9.91262 17.7852C10.1864 17.5098 10.6272 17.5098 10.901 17.7852L38.0854 45.1289C38.3592 45.4043 38.3592 45.8496 38.0854 46.125C37.9515 46.2598 37.7709 46.3281 37.5922 46.3281ZM10.4078 46.3281C10.2291 46.3281 10.0505 46.2598 9.91456 46.123C9.64078 45.8477 9.64078 45.4023 9.91456 45.127L19.6233 35.3613C19.8971 35.0859 20.3379 35.0859 20.6116 35.3613C20.8854 35.6367 20.8854 36.082 20.6116 36.3574L10.9029 46.123C10.765 46.2598 10.5864 46.3281 10.4078 46.3281ZM27.8835 28.75C27.7049 28.75 27.5243 28.6816 27.3883 28.5449C27.1146 28.2695 27.1146 27.8262 27.3883 27.5508L37.0971 17.7852C37.3709 17.5098 37.8136 17.5098 38.0874 17.7852C38.3612 18.0605 38.3612 18.5039 38.0874 18.7793L28.3786 28.5449C28.2427 28.6816 28.0621 28.75 27.8835 28.75ZM41.4757 1.95312H33.7087V3.35937H41.4757V1.95312Z" fill="black"/>
        </svg>
        <span class="features__item-value">{{data.car_power}}</span>
      </li>
    </ul>
  </div>
</template>


<script>
import {defineComponent} from "vue";

export default  defineComponent({
  name : 'Input',
  props: {
    isMobile: {
      type : Boolean
    },
    data : {
      type: [Object]
    },
    languageEng: {
      type: Boolean
    }
  },
  setup(props) {

    return {
      props
    }
  }
})
</script>

<style src="./features.css" scoped>

</style>


