const language = (/eng/).test(window.location.href)

export const validatorsMessage = {
    maxLength : {
        sumbol : 64,
        error : language ? 'Do not enter more than 64 characters' :'Не вводите более 64 символов'
    },
    minLength : language ? 'The input data must contain at least 4 characters.' : 'Вводимые данные должны содержать не менее 4 символов.',
    required : {
        error : language ? 'The entered data is incorrect' : 'Введенные данные неверны',
        success : language ? 'The entered data is correct' : 'Введенные данные корректны'
    },
    name : {
        error : language ? 'Invalid name entered' : 'Введено неверное имя',
        success : language ? 'The entered name is correct' : 'Введенное имя верно'
    },
    phone : {
        error : language ? 'Phone number is incorrect' : 'Номер телефона неверен',
        success : language ? 'Phone number is correct' : 'Номер телефона правильный'
    },
    сonfirmationPassword : {
        error : language ? 'The entered passwords do not match' : 'Введенные пароли не совпадают',
        success : language ? 'The entered passwords match' : 'Введенные пароли совпадают'
    },
    email : {
        error : language ? 'Mail is incorrect' : 'Почта неверная',
        success : language ? 'Mail is correct' : 'Почта верная'
    },
    password : {
        error : language ? 'Invalid password' : 'Неверный пароль',
        success : language ? 'Password correct' : 'Пароль верный'
    },
    imageUpload : {
        error: language ? 'Image supports another format or not uploaded!' : 'Изображение поддерживает другой формат или не загружено!'
    },
    comment : {
        error : language ? 'Description is incorrect' : 'Описание некорректно',
        success : language ? 'Description is correct' : 'Описание корректно'
    },
    age : {
        error: language ? 'You must be over 21' : 'Вы должны быть старше 21 года'
    },
    card : {
        number : {
            error : language ? 'This card number is not valid' : 'Этот номер карты недействительный'
        },
        year : {
            error : language ? 'Wrong date' : 'Ошибочная дата'
        },
        cvv_cvc : {
            error : language ? 'Wrong code' : 'Неправильный код'
        }
    }
};
const payment_methods = language ? ['Cash', 'card'] : ['Наличные', 'card'];
const card_validation = {
    master_card : /^5[1-5][0-9]{14}$/gm,
    union_pay : /^(62[0-9]{14,17})$/gm,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/gm,
    visa_master_card: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/gm
}

let cardIsValid = false;
export function isPassword (value) {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/
    return regex.test(value);
}
export function isPasswordConfirmation (value, password) {
    if(value === password.password) {
        return true
    }
    return false
}
export function checkPaymentMethod (value) {
    if (payment_methods.includes(value)) {
        return true
    }
    return false
}
export function isFullName (value) {
    const regex = /^([А-ЯЁ]{1}[а-яё]{1,28})|([A-Z]{1}[a-z]{1,28})$/u;
    return regex.test(value);
}
export function isNumberPhone(value) {
    const regex = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/
    return regex.test(value);
}
export function isAdult(value) {
    if(Number(value) >= 21 && Number(value) <= 100) {
        return true
    }
    return false
}
export function cardYear(value) {
    const regex = /[0-9]{2}\/[0-9]{2}/gm;
    return regex.test(value)
}
export function cardNumber(value) {
    Object.entries(card_validation).forEach(validation => {
        const regexp = validation[1];
        if(regexp.test(value)) {
            cardIsValid = true
            return true
        }
        cardIsValid = false
        return false
    });
    return cardIsValid
}
export function cardCvc(value) {
    const regexp = /^[0-9]{3,4}$/;
    return regexp.test(value);
}
export function checkAcceptance (boolean) {
    if(boolean) {
        return true
    }
    return false
}

export function isUpload (value) {
    if(value.isUpload) {
        return true
    }
    return false
}
