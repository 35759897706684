<template>
  <router-view v-slot="{ Component, route }">
    <Loader/>
    <SelectClosed/>
    <transition-group name="fade">
      <div class="transition-key" :key="route.name">
        <component :is="Component" :isMobile="isMobile" :languageEng="languageEng"/>
      </div>
    </transition-group>
    <PopupForm/>
    <Messages/>
  </router-view>
</template>

<style>
</style>

<script>
import {computed, defineComponent} from "vue";

import { useStore } from "vuex";
// Компоненты
import Messages from "@/components/Messages";
import SelectClosed from "@/components/SelectClosed";
import PopupForm from "@/components/template/popupForm/PopupForm";
import Loader from "@/components/Loader";

export default  defineComponent({
  components: {Loader, Messages, SelectClosed, PopupForm},
  setup() {
    const Store = useStore();
    const isMobile = computed(() => Store.getters["UI/isMobile"]);
    const languageEng = (/https:\/\/eng/).test(window.location.href)
    // Проверка на устройтво <=768 - мобильное
    if(window.innerWidth <= 768) {
      Store.dispatch('UI/changeIsMobile', true)
    }
    // Event для проверки изменения ширины экрана
    window.addEventListener("resize", () => {
      if(window.innerWidth <= 768) {
        Store.dispatch('UI/changeIsMobile', true)
      } else {
        Store.dispatch('UI/changeIsMobile', false)
      }
    });

    return {
      isMobile,
      languageEng
    }
  }
})
</script>
<style scoped>
</style>
