<template>
  <footer class="footer">
    <div class="container --container-80">
      <div class="footer-section">
        <router-link
            :to="{name: 'Index page'}"
            class="footer-link"
        >{{ languageEng ? 'Main' : 'Главная'}}</router-link>
        <router-link
            :to="{name: 'cars catalog'}"
            class="footer-link"
        >{{ languageEng ? 'Rent' : 'Аренда' }}</router-link>
        <router-link
            :to="{ name: 'about us' }"
            class="footer-link"
        >
          {{languageEng ? 'About' : 'О нас' }}
        </router-link>
        <router-link
                :to="{ name: 'blog' }"
                class="footer-link"
            >
            {{languageEng ? 'Blog' : 'Блог' }} 
        </router-link>
        <router-link
            :to="{ name: 'contacts' }"
            class="footer-link"
        >
         {{languageEng ? 'Contact' : 'Контакты' }}
        </router-link>
      </div>
      <div class="footer-section footer-information">
        <a class="footer-information__link" href="/">
          <span class="logo">Rent<span class="text-red">auto</span>24</span>
          <span class="link__signature">{{ languageEng ?'All rights reserved.' : 'Все права защищены.'}}</span>
        </a>
        <a class="footer-information__link" href="tel:995568946240">
          +995 568 946 240
          <span class="link__signature">{{ languageEng ? 'All rights reserved.' : 'Все права защищены.'}}</span>
        </a>
        <a class="footer-information__link" href="tel:995568946240">
          {{ languageEng ? '24 hours' : 'Круглосуточно'}}
          <span class="link__signature"> {{ languageEng ? '(Mo - Su)' : '(Пн - Вс)'}}</span>
        </a>
        <a class="footer-information__link" href="mailto:contact@rentauto24.ge">
          contact@rentauto24.ge
          <span class="link__signature">{{ languageEng ? 'Are there any questions? Write to us!' : 'Остались вопросы? Напишите нам!'}}</span>
        </a>
      </div>
    </div>
  </footer>
</template>


<script>
import {defineComponent} from "vue";

export default  defineComponent({
  name : 'Footer',
  props: {
    isMobile: {
      type : Boolean
    },
    languageEng: {
      type: [Boolean]
    }
  },
  setup() {
    return {

    }
  }
})
</script>

<style src="./footer.css" scoped>

</style>

