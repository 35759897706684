<template>
  <div class="select-big__wrapper --calendar">
    <div class="select-big__image">
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.75 5H6.25C5.55964 5 5 5.55964 5 6.25V23.75C5 24.4404 5.55964 25 6.25 25H23.75C24.4404 25 25 24.4404 25 23.75V6.25C25 5.55964 24.4404 5 23.75 5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 20H20.002V20.002H20V20Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 20H15.002V20.002H15V20Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 20H10.002V20.002H10V20Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 15H20.002V15.002H20V15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 15H15.002V15.002H15V15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 15H10.002V15.002H10V15Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 10H25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20 2.5V5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 2.5V5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <input
        type="datetime-local"
        class="input-calendar"
        id="meeting-time"
        :value="value"
        @input="updateValue"
    />
  </div>
</template>


<script>
import {defineComponent, ref } from "vue";

export default  defineComponent({
  name : 'Select Big Calendar',
  props: {
    modelValue : {
      type: String
    },
    value : {
      type : String
    },
    name : {
      type : String
    },
    isMobile: {
      type : Boolean
    }, 
    placeholder : {
      type : String
    }
  },
  setup(props, context) {
    const selectDate = ref('');

    function updateValue(event) {
      console.log(event.target.value)
      context.emit("update:modelValue", event.target.value);
    }

    return {
      selectDate,
      updateValue,
      props
    }
  }
})
</script>

<style src="./selectBig.css" scoped>

</style>









