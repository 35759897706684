<template>
  <div class="popup-form-template">
    <div class="popup-form__top">
      <h3 class="section-title-h4">{{title}}</h3>
      <div
          class="popup-form__close"
          @click="Store.dispatch('UI/changePopupFormIsOpen', false)"
      >
        <template v-if="!isMobile">
          <span>
              {{ languageEng ? 'Close' : 'Закрыть'}}
          </span>
        </template>
        <template v-else>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5 22.5L7.5 7.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.5 7.5L7.5 22.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </template>
      </div>
    </div>
    <div class="popup-form-template__content">
      <slot :languageEng="languageEng"></slot>
    </div>
    <div class="popup-form__bottom">
      <slot name="form__bottom"></slot>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, onUnmounted} from 'vue'
import {useStore} from "vuex";

export default  defineComponent({
  name : 'Popup Form Template',
  props: {
    title : {
      type : String,
    },
    languageEng: {
      type: [Boolean]
    }
  },
  setup() {
    const Store = useStore();
    const isMobile = computed(() => Store.getters['UI/isMobile']);
    onMounted(() => {
      document.body.classList.toggle('fixed');
    })
    onUnmounted(() => {
      document.body.classList.toggle('fixed');
    })
    return {
      Store,
      isMobile
    }
  }
})
</script>

<style src="./popupFormTemplate.css" scoped>

</style>
