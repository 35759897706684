<template>
  <div class="form">
    <Card
      @send="(value) => formData.card = {...value}"
      :languageEng="languageEng"
    />
    <Input
        label="Mail for receipts"
        placeholder="test@mail.ru"
        name="age"
        v-model="formData.email"
        @input="v$.email.$touch()"
        :options="v$.email"
        :languageEng="languageEng"
    >
      <template #message>
        <span v-if="v$.email.$dirty & v$.email.$errors.length" class="message message--error">{{v$.email.$silentErrors[0].$message}}</span>
      </template>
    </Input>
    <div class="form__bottom">
      <div
          class="form__back"
          @click="backStep"
      >
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.75 16C24.3023 16 24.75 15.5523 24.75 15C24.75 14.4477 24.3023 14 23.75 14V16ZM6.25 14C5.69772 14 5.25 14.4477 5.25 15C5.25 15.5523 5.69772 16 6.25 16L6.25 14ZM23.75 14L6.25 14L6.25 16L23.75 16V14Z" fill="#E1E1E1"/>
          <path d="M13.0429 23.2071C13.4334 23.5976 14.0666 23.5976 14.4571 23.2071C14.8476 22.8166 14.8476 22.1834 14.4571 21.7929L13.0429 23.2071ZM6.25 15L5.54289 14.2929C5.35536 14.4804 5.25 14.7348 5.25 15C5.25 15.2652 5.35536 15.5196 5.54289 15.7071L6.25 15ZM14.4571 8.20711C14.8476 7.81658 14.8476 7.18342 14.4571 6.79289C14.0666 6.40237 13.4334 6.40237 13.0429 6.79289L14.4571 8.20711ZM14.4571 21.7929L6.95711 14.2929L5.54289 15.7071L13.0429 23.2071L14.4571 21.7929ZM6.95711 15.7071L14.4571 8.20711L13.0429 6.79289L5.54289 14.2929L6.95711 15.7071Z" fill="#E1E1E1"/>
        </svg>
        {{languageEng ? 'Back to step' : 'Вернуться назад'}} {{current_step - 1}}
      </div>
      <button
          class="button"
          :class="v$.$silentErrors.length === 0 ? '' : 'form__button--disabled'"
          :disabled="v$.$silentErrors.length === 0 ? false : true"
          @click="submit"
      >{{ languageEng ? 'Оплата' : 'Payment'}}</button>
    </div>
  </div>
</template>


<script>
import {defineComponent, reactive} from "vue";
import {helpers, email, required} from "@vuelidate/validators";
import {validatorsMessage} from "../../../../../validations";
import useVuelidate from "@vuelidate/core";

// Компоненты
import Input from "@/components/forms/components/inputs/Input";
import Card from "@/components/forms/components/card/Card";
import {useStore} from "vuex";
export default  defineComponent({
  name : 'Card Entry',
  components : {Card, Input},
  emits: ['change'],
  props: {
    isMobile: {
      type : Boolean
    },
    languageEng: {
      type: Boolean
    },
    current_step: {
      type : Number
    },
    last_step: {
      type : Number
    }
  },
  setup(props, context) {
    const Store = useStore();
    const formData = reactive({
      card: {
        number : '',
        year: '',
        cvv_cvc : '',
      },
    })

    const rules = {
      card: {required},
      email : {
          email: helpers.withMessage(() => `${validatorsMessage.email.error}`, email),
      }
    }
    const v$ = useVuelidate(rules, formData)

    function submit () {
      if(v$.value.$silentErrors.length === 0) {
        context.emit('change', {card : formData});
      }
    }
    function backStep () {
      Store.dispatch('forms/setPaymentStep', props.current_step - 1);
    }
    return {
      formData,
      submit,
      backStep,
      v$
    }
  }
})
</script>

<style scoped src="../../../../assets/styles/form.css">

</style>


