<template>
  <header
      class="header"
      :class="isFixed ? 'fixed' : ''"
  >
    <div
        class="container --container-80">
      <router-link
          :to="{name: 'Index page'}"
          class="logo --logo-white"
      >
        Rent<span class="text-red">auto</span>24
      </router-link>
      <div class="header__left">
        <template v-if="!isMobile">
          <nav class="header__navigation">
            <router-link
                :to="{ name: 'Index page' }"
                class="header__navigation-link"
            >
             {{languageEng ? 'Main' : 'Главная'}}
            </router-link>
            <router-link
                :to="{ name: 'cars catalog' }"
                class="header__navigation-link"
            >
              {{languageEng ? 'Rent' : 'Аренда' }}
            </router-link>
            <router-link
                :to="{ name: 'about us' }"
                class="header__navigation-link"
            >
              {{languageEng ? 'About' : 'О нас' }}
            </router-link>
            <router-link
                :to="{ name: 'blog' }"
                class="header__navigation-link"
            >
              {{languageEng ? 'BLOG' : 'БЛОГ' }} 
            </router-link>
            <router-link
                :to="{ name: 'contacts' }"
                class="header__navigation-link"
            >
              {{languageEng ? 'Contact' : 'Контакты' }}
            </router-link>
          </nav>
          <div class="header__change-language__container">
            <a :href="'https://eng.rentauto24.ge'">
              <div class="header__change-language__item" :class="url == 'https://eng.rentauto24.ge' ? 'header__change-language__item--active' : '' "> ENG </div>
            </a>
            <a :href="'https://rentauto24.ge'">
              <div class="header__change-language__item" :class="url == 'https://rentauto24.ge' ? 'header__change-language__item--active' : '' "> RUS </div>
            </a>
          </div>
        </template>
        <template v-else>
          <div class="header__burger">
            <svg
                @click="isBurgerOpen = !isBurgerOpen"
                width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.75 21.25H26.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.75 15H26.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M3.75 8.75H26.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <transition-group name="fade">
            <Burger
                v-if="isBurgerOpen"
                @close="(isOpen) => isBurgerOpen = isOpen"
                :languageEng="languageEng"
            />
          </transition-group>
        </template>
      </div>
    </div>
  </header>
</template>


<script>
import {defineComponent, onMounted, onUnmounted, ref} from "vue";
// Компоненты
import Burger from "@/components/Burger";
export default  defineComponent({
  name : 'Header',
  props: {
    isMobile : {
      type: [Boolean]
    },
    languageEng: {
      type: [Boolean]
    }
  },
  components : {Burger},
  setup() {
    let url = window.location.origin

    const isFixed = ref(false);

    const isBurgerOpen = ref(false);

    const userPageHeight = window.screen.height

    function scrollingWatcher () {
      const currentScrollingPosition = window.pageYOffset;
      if(currentScrollingPosition <= userPageHeight) {
        isFixed.value = false
      }
      if(currentScrollingPosition >= userPageHeight) {
        isFixed.value = true
      }
    }
    onMounted(() => {
      window.addEventListener('scroll', scrollingWatcher)
    })
    onUnmounted(() => {
      window.removeEventListener('scroll', scrollingWatcher);
    })
    return {
      isFixed,
      isBurgerOpen,
      url,
    }
  }
})
</script>

<style scoped src="../assets/styles/header.css">
</style>
