import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index';

// Views
// import Error from "@/views/Error";

// Routes
import indexRoutes from './routes/Index';
import anotherRoutes from "./routes/another";

const routes = [
    indexRoutes,
    anotherRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    window.scrollTo(0,0);
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context
  })
})

export default router
