<template>
  <ul
      class="stars-list">
    <li
        v-for="star in stars"
        :key="star"
        class="star"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.986 8.47434L12.4538 2.98442C12.2751 2.59699 11.7244 2.59699 11.5457 2.98442L9.01347 8.47434C8.94064 8.63224 8.791 8.74096 8.61831 8.76144L2.61459 9.47327C2.19089 9.52351 2.02073 10.0472 2.33398 10.3369L6.77269 14.4417C6.90036 14.5597 6.95752 14.7357 6.92363 14.9062L5.74537 20.8361C5.66222 21.2545 6.1077 21.5782 6.48001 21.3698L11.7555 18.4168C11.9073 18.3319 12.0922 18.3319 12.244 18.4168L17.5195 21.3698C17.8918 21.5782 18.3373 21.2545 18.2541 20.8361L17.0759 14.9062C17.042 14.7357 17.0991 14.5597 17.2268 14.4417L21.6655 10.3369C21.9788 10.0472 21.8086 9.52351 21.3849 9.47327L15.3812 8.76144C15.2085 8.74096 15.0589 8.63224 14.986 8.47434Z" fill="#F4D35F" stroke="#F4D35F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </li>
  </ul>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "Rating",
  props : {
    stars : {
      type: Array,
    }
  },
  setup (props) {
    return {
      props,
    }
  }
})
</script>

<style  src="./rating.css" scoped>

</style>
