<template>
  <li
      class="offers__item"

  >
    <ul class="offers-top">
      <li v-if="data.another.status" class="offers-top__item --status">{{data.another.status}}</li>
      <li v-if="data.end" class="offers-top__item --time">
        <template v-if="!isMobile">
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8244 20.0655C5.80809 20.0002 1.75696 15.9504 1.69 10.9341C1.75528 5.91781 5.80505 1.86668 10.8214 1.79972C15.8377 1.865 19.8888 5.91478 19.9558 10.9311C19.8905 15.9474 15.8407 19.9985 10.8244 20.0655ZM10.8217 3.82925C6.9201 3.88127 3.77024 7.03219 3.71953 10.9338C3.77155 14.8354 6.92246 17.9852 10.8241 18.036C14.7257 17.9839 17.8755 14.833 17.9262 10.9314C17.8742 7.02982 14.7233 3.87996 10.8217 3.82925ZM15.8969 11.9465L9.80829 11.9475L9.80727 5.85895L11.8368 5.85861L11.8375 9.91767L15.8965 9.91699L15.8969 11.9465ZM19.2362 4.54629L16.1823 1.50251L17.6119 0.0643502L20.6668 3.10813L19.2362 4.54528L19.2362 4.54629ZM2.40637 4.54911L0.970237 3.11143L4.00489 0.0666301L5.44102 1.50431L2.4084 4.54911L2.40637 4.54911Z" fill="white"/>
          </svg>
          {{ languageEng ? 'Completion:' : 'Завершение:'}} {{data.end}}
        </template>
        <template v-else>
          {{data.end}}
        </template>
      </li>
    </ul>

    <router-link
        class="offers__img"
        :to="{path: data.link}"
    >
      <img :src="data.image" :alt="data.description">
    </router-link>

    <div class="offers-description">
      <p>{{data.description}}</p>
    </div>
  </li>
</template>


<script>
import {defineComponent} from "vue";

export default  defineComponent({
  name : 'Offers List Item',
  props: {
    data : {
      type: Object
    },
    isMobile : {
      type : Boolean
    },
    languageEng: {
      type: Boolean
    }
  },
  setup() {
    return {

    }
  }
})
</script>

<style src="./offers.css" scoped>

</style>
