<template>
  <div class="info-car__col">
    <h4 class="section-title-h4">{{ languageEng ? 'Overviews' : 'Подробности'}}</h4>
    <ul class="definition__list overview">
      <li
          v-if="data.car_color_s"
          class="definition__item"
      >
        <span class="definition__title">{{ languageEng ? 'Exterior color' : 'Цвет машины' }}</span>
        <span class="definition__value">{{data.car_color_s}}</span>
      </li>
      <li
          v-if="data.car_color_v"
          class="definition__item"
      >
        <span class="definition__title">{{ languageEng ? "Interior color" : 'Цвет интерьера' }}</span>
        <span class="definition__value">{{data.car_color_v}}</span>
      </li>
      <li
          v-if="data.car_engine"
          class="definition__item"
      >
        <span class="definition__title">{{ languageEng ? 'Engine' : 'Объем'}}</span>
        <span class="definition__value">{{data.car_engine}}</span>
      </li>
      <li
          v-if="data.car_privod"
          class="definition__item"
      >
        <span class="definition__title">{{ languageEng ? 'Drivetrain' : 'Трансмиссия' }}</span>
        <span class="definition__value">{{data.car_privod}}</span>
      </li>
    </ul>
  </div>
</template>


<script>
import {defineComponent} from "vue";

export default  defineComponent({
  name : 'Overview',
  props: {
    isMobile: {
      type : Boolean
    },
    data: {
      type : [Object],
    },
    languageEng: {
      type: Boolean
    }
  },
  setup() {
    return {

    }
  }
})
</script>

<style scoped>

</style>


