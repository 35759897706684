import { createStore } from 'vuex'

import UI from "@/store/modules/UI";
import messages from "@/store/modules/messages";
import forms from '@/store/modules/forms'
import cars from '@/store/modules/cars'
import blog from '@/store/modules/blog'

export default createStore({
  state: {
    API: 'https://api.rentauto24.ge'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    UI,
    messages,
    forms,
    cars,
    blog
  }
})
