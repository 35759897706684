import axios from "axios";

export default {
    namespaced : true,
    state: {
        cars: [],
        car: {},
        reviews: [],
        car_status: 0
    },

    getters: {
        getCars (state) {
            return state.cars
        },
        getCar (state) {
            return state.car
        },
        getReviews(state) {
            return state.reviews
        },
        getCarPriceOne(state) {
            return state.car_price_one
        },
        getCarPriceTwo(state) {
            return state.car_price_two
        },
        getCarPriceThree(state) {
            return state.car_price_three
        },
        getCarStatus(state) {
            return state.car_status
        }

    },

    mutations: {
        setCars (state, cars) {
            state.cars = cars
        },
        setCar (state, car) {
            state.car = car
        },
        setReviews(state, reviews) {
            state.reviews = reviews
        },
        setCarPriceOne(state, val) {
            state.car_price_one = val
        },
        setCarPriceTwo(state, val) {
            state.car_price_two = val
        },
        setCarPriceThree(state, val) {
            state.car_price_three = val
        },
        setCarStatus(state, val) {
            state.car_status = val

        }
    },   

    actions: { 
       async fetCarList(context) {
        let cars = await axios('https://api.rentauto24.ge/api/cars')
           context.commit('setCars',cars.data.message.message)
        },
        async fetCarPriceMin(context) {
        let cars = await axios.post('https://api.rentauto24.ge/api/filter_min')
            context.commit('setCars',cars.data.message.message)
        },
        async fetCarPriceMax(context) {
        let cars = await axios.post('https://api.rentauto24.ge/api/filter_max')
            context.commit('setCars',cars.data.message.message)
        },
        async fetCarById(context,id) {
           let car = await axios(`https://api.rentauto24.ge/api/cars/${id}`)
           console.log(car)
           context.commit('setCar',car.data.message.message)
           context.commit('setCarPriceOne',car.data.message.message.car_price_one)
           context.commit('setCarPriceTwo',car.data.message.message.car_price_two)
           context.commit('setCarPriceThree',car.data.message.message.car_price_three)
           context.commit('setCarStatus',car.data.message.message.car_status)
       },
       async fetReviews(context, id) {
        let reviews = await axios(`https://api.rentauto24.ge/api/reviews/${id}`)
        context.commit('setReviews',reviews.data.message.message)
    }
    }
}