<template>
  <div
      class="input-box --type-2"
      :class="options?.$error ? 'not-invalid' : ''"
  >
    <input
        class="input"
        :type="type"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :inputmode="inputmode"
        :maxlength="maxlength"
        @input="updateValue"
    >
    <svg
        v-if="options?.$error"
        class="message-icon"

        width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#FF0000" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11.9502 15.45H12.0502V15.55H11.9502V15.45Z" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12 8.44995V12.45" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <div v-if="options?.$error" class="message__wrapper">
      <slot name="message"></slot>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default  defineComponent({
  name : 'Input Type 2',
  props: {
    modelValue: {
      type : String
    },
    name : {
      type : String,
    },
    value : {
      type: String,
    },
    placeholder : {
      type: String,
      default: 'nothing...'
    },
    inputmode: {
      type : String
    },
    maxlength : {
      type: String
    },
    type : {
      type: String,
      default : 'text'
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    options : {
      type: Object
    },
  },
  setup(props, context) {
    function updateValue(event) {
      context.emit("update:modelValue", event.target.value);
    }
    return {
      props,
      updateValue
    }
  },
})
</script>

<style src="./inputType2.css" scoped>

</style>
