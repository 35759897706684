<template>
  <div
      v-if="ready"
      @click="Store.dispatch('UI/changeClickableSelect', {...currentSelectFromStore, open: !currentSelectFromStore.open})"
      class="select-miniature__wrapper">
    <h3 class="select-miniature__title">{{title}}</h3>
      <div
          class="select-miniature"
          @click="Store.dispatch('UI/changeIsOpenClosedList', true)"
      >

        <span class="select-miniature__selected">{{selectedValue.value}}</span>

        <svg class="select-miniature__icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 18.125L21.25 11.875H8.75L15 18.125Z" fill="black"/>
        </svg>
      </div>
      <transition name="fade">
        <SelectList
            :languageEng="languageEng"
            v-if="currentSelectFromStore.open"
            @click="event => event.stopPropagation()"
            :isMobile="isMobile"
            :selected="selectedValue.value"
            :selectList="selectList"
            @change="changeValue"
        />
      </transition>
  </div>
</template>


<script>
import {defineComponent, onMounted, ref} from "vue";
import { useStore } from "vuex";
// Компоненты
import SelectList from "@/components/SelectList";
export default  defineComponent({
  name : 'Select Miniature',
  components: {SelectList},
  props: {
    isMobile: {
      type : Boolean
    },
    title : {
      required: true,
      type : String,
    },
    selected : {
      type: String
    },
    selectList : {
      required: true,
      type : Array,
    },
    setParentValue: {
      required: true,
      type: Function
    },
    languageEng : {
      type: Boolean
    }
  },
  setup(props, context) {
    const Store = useStore();

    const ready = ref(false);

    const currentSelectFromStore = ref(null);

    onMounted(async () => {
      const currentSelectID = await Store.dispatch('UI/registeredSelectList');
      currentSelectFromStore.value = Store.getters['UI/findBySelectId'](currentSelectID);
      ready.value = true;
    })

    const selectedValue = ref({value : props.selected});

    function changeValue (value) {
      selectedValue.value.value = value;
      props.setParentValue(value);
      Store.dispatch('UI/changeClickableSelect', {...currentSelectFromStore.value, open: false});
      context.emit('change', value);
    }

    return {
      selectedValue,
      changeValue,
      currentSelectFromStore,
      ready,
      props,
      Store,
    }
  }
})
</script>

<style src="./selectMiniatureWithTitle.css" scoped>

</style>


