<template>
    <div class="container --container-80">
        <div class="blog">
            <div class="blog__inner">
                <h2 class="blog__title-main">{{ languageEng ? 'BLOG' : 'БЛОГ' }}</h2>
                <template v-if="blog.data.length">
                    <div class="blog__items">
                        <BlogItem v-for="(val, count) in blog.data" :key="val.id" :blog="val" :count="count"/>
                    </div>
                    <LoaderPagination v-if="loader"/>
                </template>
            </div> 
        </div> 
    </div> 
</template>


<script>
import {defineComponent, onMounted, computed, ref} from "vue";
import {useStore} from "vuex";

import BlogItem from "@/components/BlogItem";
import LoaderPagination from "@/components/LoaderPagination";

export default  defineComponent({
    name : 'Blog',
    components: {
        BlogItem,
        LoaderPagination
    },
    props: {
        isMobile: {
            type : Boolean
        },
        languageEng: {
            type: Boolean
        }
    },
    setup(props) {

        const Store = useStore();

        let loader = ref(false)
                         
        let block = ref(false)


        onMounted(async () => {
            if(props.languageEng) {
                await Store.dispatch('blog/ActionBlogEng')
            } else {
                await Store.dispatch('blog/ActionBlog')    
            }
            
            scroll()
        })
        
        let blog = computed(() => Store.getters['blog/getBlog'])

        let scroll = () => {
            window.onscroll = async () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight -  400;
                if(bottomOfWindow && !block.value && blog.value.next_page_url) {
                    console.log(blog.value)
                    loader.value = true
                    block.value = true;
                    if(props.languageEng) {
                        await Store.dispatch('blog/ActionBlogPaginationEng', blog.value.next_page_url)
                    } else {
                        await Store.dispatch('blog/ActionBlogPagination', blog.value.next_page_url)
                    }
                    loader.value = false
                    block.value = false;
                }
            }

        }

        return {
            blog,
            loader,
            block
        }


    },
})
</script>

<style scoped src="../assets/styles/blog.css">
</style>
 