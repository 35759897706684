// Layouts
import indexLayouts from '@/views/layouts/IndexLayouts'

// Views
import Index from "@/views/Index";


const indexRoutes = {
    path : '/',
    component : indexLayouts,
    children : [
        {
            path: '',
            component : Index,
            name : 'Index page',
            meta : {
                title: 'Welcome to the AvtoRent24',
                description: 'Index description...'
            }
        }
    ]
}
export default indexRoutes
