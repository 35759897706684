// Layouts

import defaultLayouts from "@/views/layouts/DefaultLayouts";

// Views
import Catalog from "@/views/Catalog";
import CarInfo from "@/views/CarInfo";
import AboutUs from "@/views/AboutUs";
import Contacts from "@/views/Contacts";
import Blog from "@/views/Blog";
import BlogInfo from "@/views/BlogInfo"
import Error from "@/views/Error"

const anotherRoutes = {
    path : '/',
    component : defaultLayouts, 
    children : [
        {
            path: 'cars',
            component : Catalog,
            name : 'cars catalog',
            meta : {
                title: 'Search Car',
                description: 'Catalog description...'
            }
        },
        {
            path: 'cars/:id',
            name : 'car info',
            component: CarInfo,
            meta : {
            //    Динамические в компоненте
            }
        },
        {
            path: 'about',
            name: 'about us',
            component: AboutUs,
            meta: {
                title: 'About Company',
                description: 'About Us description'
            }
        },
        {
            path: 'contacts',
            name: 'contacts',
            component: Contacts,
            meta: {
                title: 'About Company',
                description: 'About Us description'
            }
        },
        {
            path: 'blog',
            name: 'blog',
            component: Blog,
            meta: {
                title: 'Blog',
                description: 'About Us description'
            }
        },
        {
            path: 'blog/:id',
            name: 'blog info',
            component: BlogInfo,
            meta: {
                title: 'Blog Info',
                description: 'About Us description'
            }
        },
        {
            path: ':pathMatch(.*)*',
            name: 'NotFound info',
            component: Error,
            meta: {
                title: 'NotFound',
                description: 'NotFound'
            }
        },
    ]
    
}
export default anotherRoutes;
