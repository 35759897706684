export function formatDate (date) {

    const todayFormated = date.split(',');
    const leftFormated = todayFormated[0].split('/').join('-');
    const rightFormated = todayFormated[1].split('T').join('');

    return `${leftFormated}T${rightFormated}`.replace(/ /g, '');
}

export function getTodayDate () {
    const todayNotFormated = new Date().toLocaleString("en-ZA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    })

    return formatDate(todayNotFormated);

}
