<template>
  <div class="container block__error">
    <p class="status__error">404</p>
    <p class="status__text">{{  languageEng ? 'This page does not exist' : 'Такой страницы не существует'}}</p>
  </div>
</template>


<script>
import {defineComponent} from "vue";

export default  defineComponent({
  name : 'Error Page',
  props: {
    isMobile: {
      type : Boolean
    },
    languageEng: {
      type: Boolean
    }
  },
  setup() {
    return {

    }
  }
})
</script>


<style scoped src="../assets/styles/error.css">
</style>



