<template>
  <template v-if="!isMobile">
    <div class="slider">
      <ul
          class="slider__wrapper"
          :style="{transform: `translateX(-${slide * 100}%)`}"
      >
        <li
            v-for="page in agencies.native.length"
            :key="page"
            class="slider__item"
        >
          <div class="image__wrapper">
            <AggensSlide
                v-for="sliderItem in agencies.native[page - 1]"
                :key="sliderItem.id"
                :slide="sliderItem"
            />
          </div>
        </li>
      </ul>
      <div class="slider__navigation">
        <ul class="slider__navigation-list">
          <li
              v-for="(count_page, index) in agencies.native.length"
              :key="count_page"
              @click="slide = index"
              class="slider__navigation__item"
              :class="index === slide ? 'current' : ''"
          ></li>
        </ul>
      </div>
    </div>
  </template>
  <template v-else>
    <Swiper
        @swiper="onSwiper"
        :breakpoints="breakPoints"
        class="agencies-mobile-slider"
        wrapper-tag="ul"
    >
      <SwiperSlide
          v-for="slide in agencies.convert"
          :key="slide.id"

          tag="li"
          class="aggens-mobile-slide"
          :slidesPerView="1.2"
          :spaceBetween="10"
      >
        <div
            class="image__wrapper__item"
        >
          <img :src="slide.img" :alt="slide.location">
          <div class="image__name">
            {{slide.location}}
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  </template>
</template>


<script>
import {defineComponent, onMounted, onUpdated, ref} from "vue";
// Комопоненты
import AggensSlide from "@/components/aggensSlider/AggensSliderSlide";

// Слайдер
import {Swiper, SwiperSlide} from "swiper/vue";

export default  defineComponent({
  name : 'AggensSlider',
  components: {AggensSlide, Swiper, SwiperSlide},
  props: {
    isMobile: {
      type : Boolean
    },
    languageEng: Boolean
  },
  setup(props) {

    onMounted(() => {
      if(props.isMobile) {
        convertToOneArray()
      }
    })

    onUpdated(() => {
      if(props.isMobile) {
        convertToOneArray()
      }
    })

    const slide = ref(0);

    const agencies = ref({
      native: [
        [
          {id: 1, img: '/images/aggencies/batumi.jpg', location: props.languageEng ? 'White restaurant' : 'Вайт ресторан'},
          {id: 2, img: '/images/aggencies/batumi1.jpg', location: props.languageEng ? 'City Beach' : 'Городской пляж'},
          {id: 3, img: '/images/aggencies/batumi2.jpg', location: props.languageEng ? 'City view' : 'Вид на город'},
          {id: 4, img: '/images/aggencies/batumi3.jpg', location: props.languageEng ? 'Night city' : 'Ночной город'},
          {id: 5, img: '/images/aggencies/batumi4.jpg', location: props.languageEng ? 'Dawn' : 'Рассвет'},
        ],
        [
          {id: 6, img: '/images/aggencies/batumi5.jpg', location: props.languageEng ? 'Old city' : 'Старый город'},
          {id: 7, img: '/images/aggencies/batumi6.jpg', location: props.languageEng ? 'Walking street' : 'Прогулочная улица'},
          {id: 8, img: '/images/aggencies/batumi7.jpg', location: props.languageEng ? 'Attractions' : 'Достопримечательности'},
          {id: 9, img: '/images/aggencies/batumi8.jpg', location: props.languageEng ? 'Hotel Adjari' : 'Гостиница Аджари'},
          {id: 10, img: '/images/aggencies/batumi9.jpg', location: props.languageEng ? 'View from the sea' : 'Вид с моря'},
        ],
        // [
        //   {id: 11, img: '/images/aggencies/agencies_1.jpg', location: 'Alice'},
        //   {id: 12, img: '/images/aggencies/agencies_1.jpg', location: 'Alice'},
        //   {id: 13, img: '/images/aggencies/agencies_1.jpg', location: 'Alice'},
        //   {id: 14},
        //   {id: 15}
        // ]
      ],
      convert: [],
    })


    function convertToOneArray () {
      if(!agencies.value.convert.length) {
        [...agencies.value.native].map((Array) => {
          agencies.value.convert.push(...Array)
        })
        return
      }
    }

    const breakPoints = {
      320: {
        slidesPerView: 1.2,
        spaceBetween: 10
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 10
      },
    }

    function onSwiper (swiper) {
      swiper.$wrapperEl[0].classList.add('slider__wrapper');
    }

    return {
      agencies,
      slide,
      onSwiper,
      breakPoints
    }
  }
})
</script>

<style src="./aggensSlider.css" scoped>

</style>
<style>
/* Для слайдера */
@media screen and (max-width: 768px) {
  .slider__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
</style>
