<template>
  <router-link
      class="catalog-item"
      :to="{name: 'car info', params: {id: data.id}}"
  >
    <div class="catalog-item__image">
      <img :src="data.car_images_one" :alt="data.car_name">
      <ul class="offers-top">
        <li v-if="data.discount" class="offers-top__item --status">
          <template v-if="!isMobile">
            <span><b>-{{data.discount}}%</b> - {{ languageEng ? 'if for a month' : 'Если от месяца'}}</span>
          </template>
         <template v-else>
           <span><b>-{{data.discount}}%</b></span>
         </template>
        </li>
      </ul>
    </div>
    <div class="catalog-item__description">
      <span class="catalog-item__name">{{data.car_name}}</span>
      <div class="catalog-item__price">
        <span class="value">${{data.car_price_three}}</span> / {{ languageEng ? ' per day' : ' в день'}}
      </div>
      <router-link
          :to="{name: 'car info', params: {id: data.id}}"
          class="button"
      >{{languageEng ? 'Select' : 'Выбрать'}}</router-link>
    </div>
  </router-link>
</template>


<script>
import {defineComponent} from "vue";

export default  defineComponent({
  name : 'Catalog Item',
  props: {
    isMobile: {
      type : Boolean
    },
    data: {
      type : Object
    },
    languageEng: {
      type: Boolean
    }
  },
  setup() {
    return {

    }
  }
})
</script>

<style src="./catalogItem.css" scoped>

</style>


