<template>
  <form
      @submit.prevent="submitForm"
      class="fullscreen-section__form"
  >
    <div class="form-fssf"> 
      <SelectBig
        :placeholder="languageEng ? 'Start location' : 'Место выдачи авто'"
        type-select="location"
        :select_list="location_list" 
        :selected="formData.location"
        :setParentValue="(value) => formData.location = value"
        :languageEng="languageEng"
      />

      <SelectBigCalendar
        name="meeting-time"
        :placeholder="languageEng ? 'Trip start' : 'Дата начала аренды'"
        :value="formData.date_start"
        v-model="formData.date_start"
        :languageEng="languageEng"
      />
      <SelectBigCalendar
          name="meeting-time"
          :placeholder="languageEng ? 'Trip end' : 'Дата окончания аренды'"
          :value="formData.date_end"
          v-model="formData.date_end"
          :languageEng="languageEng"
      />

      <SelectBig
        type-select="age"
        :placeholder="languageEng ? 'Age' :'Возраст'"
        :select_list="age_list"
        :selected="formData.age"
        :setParentValue="(value) => formData.age = value"
        :languageEng="languageEng"
      />
      
    </div>
    <button
        class="button button-search-fssf"
        type="submit"
        :class="v$.$silentErrors.length === 0 ? '' : 'form__button--disabled'"
    >{{ languageEng ? 'Search' : 'Поиск'}}</button>
  </form>
</template>


<script>
import {defineComponent, reactive} from "vue";
import {required} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { useStore } from "vuex";

// Комопенты
import SelectBig from "@/components/forms/components/selectBig/SelectBig";
import SelectBigCalendar from "@/components/forms/components/selectBig/SelectBigCalendar";
import {getTodayDate} from "@/utills/date";
import router from "@/router";
 

// Utils
// import {formatDate} from "@/utills/date";

export default  defineComponent({
  name : 'Fullscreen Search Form',
  components: {SelectBig, SelectBigCalendar},
  props: {
    isMobile: {
      type : Boolean
    },
    languageEng: {
      type: Boolean
    }
  },
  setup(props) {
    const Store = useStore();
    const location_list = ['Batumi Airoport', 'Center Batumi'];
    const age_list = ['21', '22', '23','24','25+'];

    const formData = reactive({
      location : '',
      date_start : getTodayDate(),
      date_end : getTodayDate(),
      age : ''
    });

    const rules =  {
      location : {required},
      date_start : {required},
      date_end : {required},
      age : {required}, 
    } 

    const v$ = useVuelidate(rules, formData)

    function submitForm () {

      let date_form_date = new Date(formData.date_start.match(/\d\d\d\d-\d\d-\d\d/)[0].replace(/'-'/gi, '.'))

      let date_to_date = new Date(formData.date_end.match(/\d\d\d\d-\d\d-\d\d/)[0].replace(/'-'/gi, '.'))

      let date_res = (date_to_date - date_form_date) / (60 * 60 * 24 * 1000)

      if(date_res > 0) {
        Store.commit('forms/setDateMain', {date_start: formData.date_start, date_end: formData.date_end})
        v$.value.$validate();
        if(v$.value.$silentErrors.length === 0) {
          Store.dispatch('messages/appendMessage', {text: (props.languageEng ? 'Search in progress' : 'Идет поиск'), type : true})
          router.push({path:'/cars'})
        } else {
          Store.dispatch('messages/appendMessage', {text: (props.languageEng ? 'Fill in all the fields' : 'Заполните все поля'), type : false})
        }
      } else {
        Store.dispatch('messages/appendMessage', {text: (props.languageEng ? 'Fill in the dates correctly' :'Заполните даты верно'), type : false})
      }
    }

    return {
      location_list,
      age_list,
      formData,
      submitForm,
      v$
    }
  }
})
</script>

<style src="../../assets/styles/form.css" scoped>
</style>
