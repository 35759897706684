export  default  {
    namespaced : true,
    state : {
        isMobile: false,
        isOpenClosedList: false,
        allSelectFromPage : [],
        selectID: 0,
        clickableSelect: {},
        popupFormIsOpen : false,
    },
    mutations: {
        setIsMobile(state, data) {
            state.isMobile = data
        },
        addAllSelectFromPage(state, data) {
            state.selectID++
            state.allSelectFromPage.push(data);
        },
        setAllSelectFromPage (state, data) {
            state.allSelectFromPage = data;
        },
        setIsOpenClosedList (state, data) {
            state.isOpenClosedList = data;
        },
        setPopupFormIsOpen (state, data) {
            state.popupFormIsOpen = data;
        },
        setClickableSelect (state, data) {
            state.clickableSelect = data;
            state.allSelectFromPage[data.id - 1].open = data.open;
        }
    },
    actions: {
        changeIsMobile (context, data) {
            context.commit('setIsMobile', data);
        },
        changeClickableSelect (context, data) {
            context.commit('setClickableSelect', data);
        },
        changeIsOpenClosedList (context, data) {
            context.commit('setIsOpenClosedList', data);
        },
        clearAllSelectFromPage (context) {
            context.commit('setAllSelectFromPage', []);
        },
        registeredSelectList (context) {
            context.commit('addAllSelectFromPage', {id: context.getters.selectID + 1, open: false});
            return context.getters.selectID;
        },
        changePopupFormIsOpen (context, data) {
            context.commit('setPopupFormIsOpen', data);
        }
    },
    getters : {
        isMobile (state) {
            return state.isMobile;
        },
        allSelectFromPage (state) {
            return state.allSelectFromPage;
        },
        selectID (state) {
            return state.selectID;
        },
        findBySelectId: state => id => {
            return state.allSelectFromPage.find(elem => elem.id === id);
        },
        clickableSelect (state) {
            return state.clickableSelect;
        },
        isOpenClosedList (state) {
            return state.isOpenClosedList;
        },
        popupFormIsOpen (state) {
           return state.popupFormIsOpen
        }
    }
}
