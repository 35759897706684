<template>
  <form
      class="form contact-us"
      @submit.prevent="submitForm"
  >
    <h3 class="form-title">{{languageEng ? 'Contact us' : 'Свяжитесь с нами'}}</h3>
    <inputType2
      name="Name"
      :placeholder="languageEng ? 'Name' : 'Имя'"
      v-model="formData.name"
      @input="v$.name.$touch()"
      :options="v$.name"
      :languageEng="languageEng"
    >
      <template #message>
          <span v-if="v$.name.$dirty & v$.name.$errors.length" class="message message--error">{{v$.name.$silentErrors[0].$message}}</span>
      </template>

    </inputType2>
    <inputType2
        :languageEng="languageEng"
        name="Email"
        :placeholder="languageEng ? 'Email': 'Эл. почта'"
        v-model="formData.email"
        @input="v$.email.$touch()"
        :options="v$.email"
    >
      <template #message>
        <span v-if="v$.email.$dirty & v$.email.$errors.length" class="message message--error">{{v$.email.$silentErrors[0].$message}}</span>
      </template>
    </inputType2>
    <InputTextAreaType2
        :languageEng="languageEng"
        name="Message"
        :placeholder=" languageEng ? 'Message' : 'Сообщение'"
        v-model="formData.message"
        @input="v$.message.$touch()"
        :options="v$.message"
    >
      <template #message>
        <span v-if="v$.message.$dirty & v$.message.$errors.leFngth" class="message message--error">{{v$.message.$silentErrors[0].$message}}</span>
      </template>
    </InputTextAreaType2>
    <button
        class="button bg--brown"
        :class="v$.$silentErrors.length === 0 ? '' : 'form__button--disabled'"
        :disabled="v$.$silentErrors.length === 0 ? false : true"
        type="submit"
    >{{languageEng ? 'Sent' : 'Отправить'}}</button>
  </form>
</template>


<script>
import {defineComponent, reactive} from "vue";
import useVuelidate from '@vuelidate/core'
import { required, email,  helpers } from '@vuelidate/validators'
import { useStore } from 'vuex'
// Компоненты
import inputType2 from '@/components/forms/components/inputs/InputType2';
import InputTextAreaType2 from '@/components/forms/components/textareas/InputTextAreaType2'
// Utils
import { validatorsMessage, isFullName } from "../../../validations";

export default  defineComponent({
  name : 'Contact us',
  components : { inputType2, InputTextAreaType2},
  props: {
    isMobile: {
      type : Boolean
    },
    languageEng: {
      type: Boolean
    }
  },
  setup(props) {
    const Store = useStore();

    const formData = reactive({
      name : '',
      email : '',
      message: ''
    })

    const rules =  {
        name : {
          isFullName : helpers.withMessage(() => `${validatorsMessage.name.error}`, isFullName),
        },
        email: {
          email: helpers.withMessage(() => `${validatorsMessage.email.error}`, email)
        },
        message: {
          required : helpers.withMessage(() => `${validatorsMessage.required.error}`, required)
        }
    }


    const v$ = useVuelidate(rules, formData)


    function submitForm () {
      if(v$.value.$silentErrors.length === 0) { 
        Store.dispatch('forms/SendMe', formData);
        Store.dispatch('messages/appendMessage', {text : (props.languageEng ? 'Sent' : 'Отправлено'), type : true});
      } else {
        Store.dispatch('messages/appendMessage', {text : (props.languageEng ? 'Error submitting the form' : 'Ошибка при отправке формы'), type : false});
      }
    }

    return {
      formData,
      v$,
      submitForm
    }
  }
})
</script>

<style src="./ContacUs.css" scoped>

</style>
