import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar';

import { createHead } from '@vueuse/head'

import '@/assets/styles/fonts.css'
import '@/assets/styles/style.css'
import '@/assets/styles/animation.css'
import 'v-calendar/dist/style.css';


const head = createHead();

createApp(App)
    .use(store)
    .use(router)
    .use(head)
    .use(VCalendar)
    .mount('#app')
