<template>
  <div class="section-title-wrapper">
    <h3 class="section-title-h3">{{data.length}}{{languageEng ? ' Review(s)' : ' Отзыв(а)'}} </h3>
  </div>
    <Swiper
        class="reviews-slider"
        wrapper-tag="ul"
        :centeredSlides="false"
        @swiper="onSwiper"
        :breakpoints="breakPoints"
        :modules="modules"
    >
      <SwiperSlide
          tag="li"
          v-for="(slide, index) in data"
          :key="index"
          class="reviews-slider__item"
      >
        <p>{{languageEng ? slide.description_eng : slide.description}}</p>
        <div class="user">
          <img class="user-avatar" src="/images/user-avatar.jpg" :alt="languageEng ? slide.name_eng : slide.name">
          <div class="user__info">
            <span class="user__info-name">{{languageEng ? slide.name_eng : slide.name}}</span>
            <Rating
              :stars="checkStars(slide.status)"
            />
          </div>
        </div> 
      </SwiperSlide>
    </Swiper>
</template>


<script>
import {defineComponent} from "vue";
import Rating from "@/components/rating/Rating";
import { Swiper, SwiperSlide } from "swiper/vue";

export default  defineComponent({
  name : 'Reviews',

  components : {Rating, Swiper, SwiperSlide},
  props: {
    isMobile: {
      type : Boolean 
    },
    data: {
      type : [Array]
    },
    languageEng: {
      type: Boolean
    }
  },
  setup(props) {

    const breakPoints = { 
      1920: {
        slidesPerView: 2,
        spaceBetween: 10,
      }, 
      1366: {
        slidesPerView: 2,
        spaceBetween: 30,
      }
    }
    function onSwiper (swiper) {
      console.log(swiper.$wrapperEl[0])
      // swiper.$wrapperEl[0].classList.add('car-slider__wrapper')
    }
    
    function checkStars (count) {
      let tempArr = []

      for(let j = 0; j < count; j++) {
        tempArr.push({star: true})
      }
      return tempArr
    }


    return {
      props,
      breakPoints,
      onSwiper,
      checkStars
    }
  }
})
</script>

<style src="./reviews.css" scoped>

</style>
