<template>
   <PopupFormTemplate
          :title="`${current_step.title} (${payment_data.current_step})`"
          :languageEng="languageEng"
      >
      <template v-if="current_step.step <= last_step">
        <keep-alive>
          <Component
              :languageEng="languageEng"
              :is="current_step.component"
              :current_step="current_step.step"
              :last_step="last_step"
              @change="nextForm"
          />
        </keep-alive>
      </template>

      </PopupFormTemplate>
</template>

<script>
import {defineComponent, shallowRef, ref, computed, onUnmounted, onUpdated} from "vue";
import {useStore} from "vuex";
// Компоненты

import SelectPaymentMethod from "@/components/forms/payment/selectPaymentMethod/SelectPaymentMethod";
import PaymentInfo from "@/components/forms/payment/paymentInfo/PaymentInfo";
import CardEntry from "@/components/forms/payment/cardEntry/CardEntry";
import PopupFormTemplate from "@/components/template/popupFormTemplate/popupFormTemplate";

export default defineComponent({
  name: "Payments Forms Controller",
  components : {PopupFormTemplate, SelectPaymentMethod, PaymentInfo, CardEntry},
  props: {
    languageEng: {
      type: Boolean
    },
  },
  setup (props) {
    const Store = useStore();
    const payment_data = computed(() => Store.getters['forms/paymentFormData']);
    const last_step = ref(2);
    const step_all = [
      {
        step : 1,
        name : 'payment_method',
        title : props.languageEng ? 'Payment Choice' : 'Выбор оплаты',
        component : SelectPaymentMethod
      },
      {
        step : 2,
        name : 'payment_info',
        title : props.languageEng ? 'Fill the form -' : 'Заполните форму - ',
        component : PaymentInfo
      },
      {
        step : 3,
        payment : 'card',
        title : 'Form of payment',
        name : 'payment_card',
        component : CardEntry
      },
    ]
    const current_step = shallowRef(step_all[payment_data.value.current_step - 1]);

    function nextForm (value) {

      if(payment_data.value.current_step < last_step.value) {
        Store.dispatch('forms/setPaymentStep', payment_data.value.current_step + 1);
        Store.dispatch('forms/AddingValuePaymentFormData', value);
      } else {
        Store.dispatch('messages/appendMessage', {text: (props.languageEng ? 'Submit Confirm' : 'Форма отправлена'), type: true})
        Store.dispatch('UI/changePopupFormIsOpen', false);
      }

      if(payment_data.value.payment_method === (props.languageEng ? 'Cash' : 'Наличные')) {
        last_step.value = 2;
      } else {
        last_step.value = 3 
      }
    }
    onUpdated(() => {
      current_step.value = step_all[payment_data.value.current_step - 1];
    })
    onUnmounted(() => { 
      Store.dispatch('forms/setPaymentStep', 1);
    })

    return {
      props,
      step_all,
      current_step,
      payment_data,
      last_step,
      nextForm
    }
  }
})
</script>

<style scoped>

</style>
