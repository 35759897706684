<template>
  <div
      v-if="isOpen"
      @click="closed"
      class="closed-list"
  >
  </div>
</template>


<script>
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";

export default  defineComponent({
  name : 'Select Closed',
  props: {
    isMobile: {
      type : Boolean
    }
  },
  setup() {
    const Store = useStore();
    const isOpen = computed(() => Store.getters['UI/isOpenClosedList']);

    function closed () {
      const currentSelect = computed(() => Store.getters['UI/clickableSelect']);
      Store.dispatch('UI/changeIsOpenClosedList', false);
      Store.dispatch('UI/changeClickableSelect', {...currentSelect.value, open: !currentSelect.value.open})
    }

    return {
      isOpen,
      closed,
      Store
    }
  }
})
</script>

<style scoped>
  .closed-list {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
</style>


