import axios from "axios";

export default {
    namespaced : true,
    state: {
        blog: {
            data: []
        },
        blogId: {
            description: ''
        },
    },

    getters: { 
        getBlog(state) {
            return state.blog
        },
        getBlogId(state) {
            return state.blogId
        },
    },

    mutations: {
        setBlog(state, blog) {
            state.blog = blog
        },
        setBlogId(state, blog) {
            state.blogId = blog
        },
        setBlogPaination(state, blog) {
            state.blog.data = [...state.blog.data, ...blog.data]
            state.blog.current_page = blog.current_page
            state.blog.next_page_url = blog.next_page_url
        }
    },   

    actions: { 
        async ActionBlog(context) {
            let res = await axios.post(`${context.rootState.API}/api/blog/`)
            context.commit('setBlog', res.data.message.message)
        },
        async ActionBlogPagination(context, link) {
            let res = await axios.post(link)
            context.commit('setBlogPaination', res.data.message.message)
        },
        async ActionBlogId  (context, id) {
            let res = await axios.get(`${context.rootState.API}/api/blog/${id}`)
            context.commit('setBlogId', res.data.message.message)
        },
        async ActionBlogEng(context) {
            let res = await axios.post(`${context.rootState.API}/api/eng/blog`)
            context.commit('setBlog', res.data.message.message)
        },
        async ActionBlogPaginationEng(context, link) {
            let res = await axios.post(link)
            context.commit('setBlogPaination', res.data.message.message)
        },
        async ActionBlogIdEng(context, id) {
            let res = await axios.get(`${context.rootState.API}/api/eng/blog/${id}`)
            context.commit('setBlogId', res.data.message.message)
        },
    }
}