<template>
    <div class="blog__item">
      <router-link
        :to="{name: 'blog info', params: {id: blog.id}}"
      >
        <div class="blog__inner-item">
          <div class="blog__img" :style="{ backgroundImage: `url(${blog.images})`}" :src="blog.images" alt=""></div>
          <div class="blog__info">
              <h3 class="blog__title">{{blog.title}}</h3>
              <div class="blog__text__block">
                <p class="blog__text" v-html="blog.description"></p>
              </div>
          </div>
          <p class='blog__date' v-text="changeTime()"></p>
        </div>
        </router-link>
    </div>
</template>


<script>

    import {defineComponent} from "vue";
    
    export default  defineComponent({
      name : 'Blog Item',
      props: {
        isMobile: {
          type : Boolean
        },
        blog: {
          type : Object
        }, 
        languageEng: {
          type: Boolean
        },
        count: {
            type: Number
        }
      },
      setup(props) {

        const changeTime = () => {
            let date = props.blog.created_at.match(/\d\d\d\d-\d\d-\d\d/)[0].replace(/^(\d+)-(\d+)-(\d+)$/, `$3.$2.$1`)
            let time = props.blog.created_at.match(/\d\d:\d\d:\d\d/)[0]
            return `${date + ' ' + time}`
        }

        return {
            changeTime
        }
      }
    })
</script>


<style scoped src="../assets/styles/blog.css">
</style>

<style>
  @media screen and (max-width: 768px) { 
    .blog__text span {
      font-size: 2.3vw !important;
    }
  }
</style>