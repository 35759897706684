<template>
  <div
      :class="isOpenList ? 'selected' : ''"
      class="select-big__wrapper"
  >
    <div
        v-if="isOpenList"
        @click="isOpenList = !isOpenList"
        class="closed-list">
    </div>
    <div
        @click="isOpenList = !isOpenList"
        class="select-big"
    >
      <div class="select-big__image">

        <svg v-if="typeSelect === 'location'" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 10C13.6193 10 12.5 11.1193 12.5 12.5C12.5 13.8807 13.6193 15 15 15C16.3807 15 17.5 13.8807 17.5 12.5C17.5 11.1193 16.3807 10 15 10Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.625 21.8363C11.9577 23.4269 13.4212 24.9033 15 26.25C16.5788 24.9033 18.0423 23.4269 19.375 21.8363C21.375 19.4475 23.75 15.89 23.75 12.5C23.7574 10.178 22.8356 7.94951 21.19 6.31125C18.6876 3.80655 14.9224 3.05676 11.6513 4.41175C8.38031 5.76674 6.24823 8.95943 6.25 12.5C6.25 15.89 8.625 19.4475 10.625 21.8363ZM8.75 12.5C8.75414 9.04994 11.5499 6.25413 15 6.25C18.4501 6.25413 21.2459 9.04994 21.25 12.5C21.25 13.9563 20.5913 16.48 17.4575 20.2363C16.6841 21.1573 15.8638 22.0381 15 22.875C14.1363 22.0371 13.3164 21.1551 12.5438 20.2325C9.40875 16.4813 8.75 13.9575 8.75 12.5Z" fill="white"/>
        </svg>
        <svg v-else-if="typeSelect === 'age'" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25 25H5C3.66314 25.0401 2.54552 23.9917 2.5 22.655V7.3438C2.54552 6.00731 3.66335 4.95933 5 5.00005H25C26.3366 4.95933 27.4545 6.00731 27.5 7.3438V22.6563C27.4538 23.9925 26.3364 25.0401 25 25ZM5 7.50005V22.4863L25 22.5V7.5138L5 7.50005ZM16.7875 20H7.5C7.59191 18.9651 8.08168 18.0062 8.86625 17.325C9.73978 16.4584 10.9135 15.9616 12.1438 15.9375C13.374 15.9616 14.5477 16.4584 15.4212 17.325C16.2056 18.0064 16.6953 18.9652 16.7875 20ZM22.5 18.75H18.75V16.25H22.5V18.75ZM12.1438 15C11.4738 15.0233 10.8243 14.7674 10.3504 14.2934C9.87638 13.8195 9.62047 13.17 9.64375 12.5C9.62085 11.8302 9.87688 11.181 10.3508 10.7071C10.8247 10.2332 11.4739 9.97714 12.1438 10C12.8136 9.97714 13.4628 10.2332 13.9367 10.7071C14.4106 11.181 14.6667 11.8302 14.6438 12.5C14.667 13.17 14.4111 13.8195 13.9371 14.2934C13.4632 14.7674 12.8137 15.0233 12.1438 15ZM22.5 13.75H17.5V11.25H22.5V13.75Z" fill="white"/>
        </svg>

      </div>
      <span
          class="select-big__selected"
          :class="selected ? '' : '--placeholder'"
      >{{selected || placeholder}}</span>
      <div
          class="select-big__toggle"
      >
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 18.125L21.25 11.875H8.75L15 18.125Z" fill="white"/>
        </svg>
      </div>
      <transition name="fade">
        <SelectList
            :languageEng="languageEng"
            v-if="isOpenList"
            :selected="selected"
            :selectList="select_list"
            @change="changeValue"
        />
      </transition>
    </div>
  </div>
</template>


<script>
import {defineComponent, ref} from "vue";
// Комопненты
import SelectList from "@/components/SelectList";

export default  defineComponent({
  name : 'Select Big',
  components : {SelectList},
  props: {
    isMobile: {
      type : Boolean
    },
    type : {
      type: String,
      default : 'location'
    },
    select_list : {
      type : Array,
    },
    placeholder: {
      type: String,
    },
    typeSelect : {
      type : String
    },
    selected : {
      type: [String]
    },
    setParentValue: {
      type : [Function]
    },
    style: {
      type : Object
    }
  },
  setup(props) {

    const isOpenList = ref(false);

    function changeValue (value) {
      props.setParentValue(value);
    }

    return {
      isOpenList,
      changeValue,
      props
    }
  }
})
</script>

<style src="./selectBig.css" scoped>

</style>



